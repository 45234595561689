// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback,
.user-guide,
.rating {
  text-align: center;
  margin-top: 15px;
}
.feedback a,
.user-guide a,
.rating a {
  margin-left: 0;
  margin-right: 0;
}
.feedback img,
.user-guide img,
.rating img {
  width: 30px;
}
.feedback span,
.user-guide span,
.rating span {
  display: block;
  color: #000;
  font-size: 12px;
}

.rating {
  margin-top: 10px;
}
.rating a:hover {
  cursor: pointer;
}
.rating a > i {
  color: #0B2D71 !important;
  font-size: 35px;
}

.flagBox {
  float: right;
  width: 100px;
  height: 75px;
}

.flag {
  max-width: 50%;
  max-height: 50%;
}

:host .user {
  display: flex;
  padding: 20px;
  color: #0b2d71;
  font-family: "GothamBook";
}
:host .user img {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-left: 15px;
}
:host .user span {
  display: grid;
  font-size: 16px;
  font-weight: bolder;
}
:host .user span span {
  font-size: 12px;
  font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/app/header/header.component.scss"],"names":[],"mappings":"AAEA;;;EAGE,kBAAA;EACA,gBAAA;AADF;AAEE;;;EACE,cAAA;EACA,eAAA;AAEJ;AAAE;;;EACE,WAAA;AAIJ;AAFE;;;EACE,cAAA;EACA,WAAA;EACA,eAAA;AAMJ;;AAFA;EACE,gBAAA;AAKF;AAJE;EACE,eAAA;AAMJ;AAJE;EACE,yBAAA;EACA,eAAA;AAMJ;;AAHA;EACE,YAAA;EACA,YAAA;EACA,YAAA;AAMF;;AAHA;EACE,cAAA;EACA,eAAA;AAMF;;AAHE;EACE,aAAA;EACA,aAAA;EACA,cAAA;EACA,yBAAA;AAMJ;AALI;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;AAON;AALI;EACE,aAAA;EACA,eAAA;EACA,mBAAA;AAON;AANM;EACE,eAAA;EACA,gBAAA;AAQR","sourcesContent":["@import \"app/colors.scss\";\n\n.feedback,\n.user-guide,\n.rating {\n  text-align: center;\n  margin-top: 15px;\n  a {\n    margin-left: 0;\n    margin-right: 0;\n  }\n  img {\n    width: 30px;\n  }\n  span {\n    display: block;\n    color: #000;\n    font-size: 12px;\n  }\n}\n\n.rating {\n  margin-top: 10px;\n  a:hover {\n    cursor: pointer;\n  }\n  a > i {\n    color: $darkblue !important;\n    font-size: 35px;\n  }\n}\n.flagBox{\n  float: right;\n  width:100px;\n  height: 75px;\n\n}\n.flag{\n  max-width:50%;\n  max-height:50%;\n}\n:host {\n  .user {\n    display: flex;\n    padding: 20px;\n    color: #0b2d71;\n    font-family: \"GothamBook\";\n    img {\n      border-radius: 50%;\n      height: 35px;\n      width: 35px;\n      margin-left: 15px;\n    }\n    span {\n      display: grid;\n      font-size: 16px;\n      font-weight: bolder;\n      span {\n        font-size: 12px;\n        font-weight: 300;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
