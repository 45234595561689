// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .pi {
  font-size: 10px;
  padding: 0 10px;
}
:host ::ng-deep .pi:focus {
  outline: none;
}
:host .breadcrumb {
  margin: 0;
  background-color: #f7f7f8;
  text-align: left;
  font-size: 12px;
  font-family: "GothamNarrowBook", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-transform: lowercase;
}
:host .breadcrumb label {
  color: #0066b2;
  cursor: pointer;
}
:host .breadcrumb label.last {
  color: #8c8f93;
  cursor: default;
}
:host .breadcrumb label:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/breadcrumb/breadcrumb.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,eAAA;AAAJ;AACI;EACE,aAAA;AACN;AAEE;EACE,SAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,+EAAA;EAEA,iBAAA;EACA,yBAAA;AADJ;AAEI;EACE,cAAA;EACA,eAAA;AAAN;AACM;EACE,cAAA;EACA,eAAA;AACR;AACM;EACE,aAAA;AACR","sourcesContent":[":host {\n  ::ng-deep .pi {\n    font-size: 10px;\n    padding: 0 10px;\n    &:focus {\n      outline: none;\n    }\n  }\n  .breadcrumb {\n    margin: 0;\n    background-color: #f7f7f8;\n    text-align: left;\n    font-size: 12px;\n    font-family: \"GothamNarrowBook\", \"Helvetica Neue\", Helvetica, Arial,\n      sans-serif;\n    letter-spacing: 0;\n    text-transform: lowercase;\n    label {\n      color: #0066b2;\n      cursor: pointer;\n      &.last {\n        color: #8c8f93;\n        cursor: default;\n      }\n      &:focus {\n        outline: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
