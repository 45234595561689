import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Question } from "#models/job-templates/section";
import { QuestionOptionService } from "./question-option.service";
import { QuestionOption } from "#models/job-templates/question-option.model";
import { Observable } from "rxjs";

@Injectable()
export class QuestionService {
  constructor(
    private http: HttpClient,
    private readonly _questionOptionService: QuestionOptionService
  ) {}

  get(
    jobTemplateId?: string,
    sectionId?: string,
    expand?: boolean
  ): Observable<Array<Question>> {
    const params = {
      ...(jobTemplateId && { jobTemplateId }),
      ...(sectionId && { sectionId }),
      ...(expand && { expand: expand + "" }),
    };
    return this.http.get<Array<Question>>(
      `${environment.apiEndpoint}/questions`,
      {
        params,
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      }
    );
  }

  post(payload: Question): Observable<Question> {
    return this.http.post<Question>(
      `${environment.apiEndpoint}/questions`,
      payload
    );
  }

  put(payload: Question): Observable<Question> {
    return this.http.put<Question>(
      `${environment.apiEndpoint}/questions/${payload.ID}`,
      { ...payload, id: payload.ID }
    );
  }

  updateQuestionOrder(
    sectionId: string,
    payload: Question[]
  ): Observable<Question> {
    return this.http.put<Question>(
      `${environment.apiEndpoint}/questions/${sectionId}/update-question-order`,
      payload
    );
  }

  delete(id: string): Observable<Question> {
    return this.http.delete<Question>(
      `${environment.apiEndpoint}/questions/${id}`
    );
  }

  getBySection(sectionId: string): Observable<Array<Question>> {
    return this.http.get<Array<Question>>(
      `${environment.apiEndpoint}/questions?sectionId=${sectionId}&expand=true&orderBy=Order`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  getQuestionOptions(payload: QuestionOption) {
    return this._questionOptionService.post(payload);
  }
}
