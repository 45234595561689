import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { SmartActivityId } from "#models/variables/logic-question.model";
import { Observable } from "rxjs";

@Injectable()
export class SmartActivityService {
  constructor(private http: HttpClient) {}

  getList(): Observable<Array<SmartActivityId>> {
    return this.http.get<Array<SmartActivityId>>(
      `${environment.apiEndpoint}/smartactivityids`
    );
  }

  get(
    smartActivityId?: string
  ): Observable<SmartActivityId[] | SmartActivityId> {
    const trade = smartActivityId ? "/" + SmartActivityId : "";
    return this.http.get<any>(
      `${environment.apiEndpoint}/smartactivityids` + trade
    );
  }

  post(payload: any): Observable<SmartActivityId> {
    return this.http.post<SmartActivityId>(
      `${environment.apiEndpoint}/smartactivityids`,
      payload
    );
  }

  put(payload: any): Observable<SmartActivityId> {
    return this.http.put<SmartActivityId>(
      `${environment.apiEndpoint}/smartactivityids/${payload.ID}`,
      payload
    );
  }
}
