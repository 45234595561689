import { environment } from "#environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PrimaveraService {
  constructor(private http: HttpClient) {}

  getProjects(user?: string): Observable<Array<{ [key: number]: string }>> {
    const params = user ? { user } : {};
    return this.http.get<Array<{ [key: number]: string }>>(
      `${environment.apiEndpoint}/primavera/projects`,
      { params }
    );
  }

  getWbsObjects(
    projectId: number
  ): Observable<Array<{ [key: number]: string }>> {
    return this.http.get<Array<{ [key: number]: string }>>(
      `${environment.apiEndpoint}/primavera/projects/${projectId}/wbs`
    );
  }
}
