import { Inject, Injectable } from "@angular/core";
import { LoadingIndicatorService } from "./loading-indicator.service";
import { UserService } from "#services/api";
import { LoggedUser } from "models";
import { Observable } from "rxjs";
import { IUserRole } from "helpers/interfaces/user/user-role.interface";
import { concatMap, finalize, map } from "rxjs/operators";
import {
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";
import { AuthenticationResult, RedirectRequest } from "@azure/msal-browser";
import { environment } from "#environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private readonly _loadingIndicatorSvc: LoadingIndicatorService,
    private readonly _userService: UserService,
    private readonly _msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG)
    private readonly _msalGuardConfig: MsalGuardConfiguration
  ) {}

  getToken(): string {
    return sessionStorage.getItem("Token") || null;
  }

  getAuthorizationHeader(): string {
    const token = this.getToken();
    return token ? `Bearer ${token}` : "";
  }

  getFullName(): string {
    return sessionStorage.getItem("FullName") || "";
  }

  isAuthenticated(): boolean {
    return this._msalService.instance.getAllAccounts()?.length > 0 || false;
  }

  isAuthorized(): boolean {
    return this._msalService.instance.getAllAccounts()?.length > 0 || false;
  }

  getCurrentUser(): any {
    //Including Role in Current User object
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (currentUser !== null && currentUser !== undefined)
      currentUser.Role = JSON.parse(sessionStorage.getItem("Role"));
    return currentUser;
  }

  isSystemAdmin(): boolean {
    return Boolean(sessionStorage.getItem("IsSystemAdmin"));
  }

  IsUserDisabled(): boolean {
    return sessionStorage.getItem("IsDisabled")?.toLowerCase() === "true";
  }

  login(): void {
    if (this._msalGuardConfig.authRequest) {
      this._msalService.loginRedirect({
        ...this._msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this._msalService.loginRedirect();
    }
  }

  logout(): void {
    this._msalService.logoutRedirect();
    sessionStorage.clear();
  }

  acceptDisclaimer(userId: number) {
    this._loadingIndicatorSvc.show();
    this._userService
      .acceptDisclaimer(userId)
      .pipe(
        finalize(() => {
          this._loadingIndicatorSvc.hide();
        })
      )
      .subscribe(() => {
        const currentUser = this.getCurrentUser();
        currentUser.DisclaimerAccepted = true;
        sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
        window.location.reload();
      });
  }

  getUserData(): Observable<any> {
    const account = this._msalService.instance.getActiveAccount();
    return this._msalService
      .acquireTokenSilent({
        scopes: environment.msalConfig.scopes,
        account: account,
      })
      .pipe(
        concatMap((tokenResponse: AuthenticationResult) => {
          sessionStorage.setItem("Token", tokenResponse.accessToken);
          return this._userService.getCurrentUser().pipe(
            concatMap((loggedUser: LoggedUser) => {
              sessionStorage.setItem("FullName", tokenResponse.account.name);
              sessionStorage.setItem("cai", loggedUser.CAI);
              sessionStorage.setItem("Email", loggedUser.Email);
              sessionStorage.setItem(
                "bu",
                loggedUser.BusinessUnit?.Name || "Unknown"
              );
              sessionStorage.setItem(
                "IsSystemAdmin",
                JSON.stringify(loggedUser.IsSystemAdmin)
              );
              sessionStorage.setItem(
                "IsDisabled",
                JSON.stringify(loggedUser.IsDisabled)
              );

              sessionStorage.setItem("currentUser", JSON.stringify(loggedUser));
              return this._userService.getRoles(loggedUser.ID).pipe(
                map((roles: IUserRole[]) => {
                  sessionStorage.setItem("Role", JSON.stringify(roles));
                })
              );
            })
          );
        })
      );
  }

  getRoles(): IUserRole[] {
    return JSON.parse(sessionStorage.getItem("Role")) || [];
  }
}
