import { Component, OnInit } from "@angular/core";
import { AuthService } from "#services/shared";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationInsightsService } from "#services/shared/applicationInsights.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private authSvc: AuthService,
    public translate: TranslateService,
    private appInsightService: ApplicationInsightsService
  ) {
    const user = this.authSvc.getCurrentUser();
    if (user) {
      this.appInsightService.setUser(user.Email);
    }
    translate.addLangs(["en", "ru", "kz"]);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");
  }

  showDisclaimer: boolean;
  currentUserId: number;

  ngOnInit(): void {
    const currentUser = this.authSvc.getCurrentUser();
    if (currentUser) {
      this.currentUserId = currentUser.ID;
      sessionStorage.setItem("user", this.currentUserId.toString());
      this.showDisclaimer = !currentUser.DisclaimerAccepted;
    }
  }

  iAccept() {
    this.authSvc.acceptDisclaimer(this.currentUserId);
  }
}
