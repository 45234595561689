import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { SubArea } from "#models/sub-area";
import { Observable } from "rxjs";

@Injectable()
export class SubAreaService {
  constructor(private http: HttpClient) {}

  public getAll(): Observable<SubArea[]> {
    return this.http.get<SubArea[]>(`${environment.apiEndpoint}/sub-area`);
  }

  public getAllByAreaId(areaId: number): Observable<SubArea[]> {
    return this.http.get<SubArea[]>(
      `${environment.apiEndpoint}/sub-area/getByArea/${areaId}`
    );
  }

  public getSubAreaById(id: number): Observable<SubArea> {
    return this.http.get<SubArea>(`${environment.apiEndpoint}/sub-area/${id}`);
  }

  public createSubArea(area: SubArea): Observable<SubArea> {
    return this.http.post<SubArea>(`${environment.apiEndpoint}/sub-area`, area);
  }

  public updateSubArea(area: SubArea): Observable<SubArea> {
    return this.http.put<SubArea>(`${environment.apiEndpoint}/sub-area`, area);
  }

  public deleteSubArea(id: number): Observable<SubArea> {
    return this.http.put<SubArea>(
      `${environment.apiEndpoint}/sub-area/delete/${id}`,
      null
    );
  }
}
