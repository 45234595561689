import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Section } from "#models/job-templates/section";
import { Observable } from "rxjs";

@Injectable()
export class SectionsService {
  constructor(private http: HttpClient) {}

  get(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.apiEndpoint}/sections`);
  }

  getByTemplate(jobTemplateId?: string): Observable<Section[]> {
    return this.http.get<any>(
      `${environment.apiEndpoint}/sections?jobTemplateId=${jobTemplateId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  post(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/sections`, payload);
  }

  put(payload: any): Observable<any> {
    return this.http.put<any>(
      `${environment.apiEndpoint}/sections/${payload.ID}`,
      payload
    );
  }

  delete(sectionId: string): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiEndpoint}/sections/${sectionId}`
    );
  }
}
