import { Component, Input } from "@angular/core";

@Component({
  selector: "app-estimate-status",
  templateUrl: "./estimate-status.component.html",
  styleUrls: ["./estimate-status.component.scss"],
})
export class EstimateStatusComponent {
  @Input()
  estimateStatus: number;
  private _type: string;
  @Input()
  private set type(type: string) {
    this._type = type;
  }
  private get type(): string {
    return this._type;
  }
}
