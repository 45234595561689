import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { LogicQuestion } from "#models/variables/logic-question.model";
import { LogicCondition } from "#models/variables/logic-condition.model";
import { Observable } from "rxjs";

@Injectable()
export class LogicQuestionService {
  constructor(private http: HttpClient) {}

  get(filter: {
    jobTemplateId?: string;
    expand?: boolean;
    logicQuestion?: string;
  }): Observable<LogicQuestion[] | LogicQuestion> {
    const logicQuestion =
      filter && filter.logicQuestion ? "/" + filter.logicQuestion : "";
    const params =
      logicQuestion === ""
        ? {
            ...(filter &&
              filter.jobTemplateId && { jobTemplateId: filter.jobTemplateId }),
            ...(filter && filter.expand && { expand: filter.expand + "" }),
          }
        : {};
    return this.http.get<any>(
      `${environment.apiEndpoint}/logic-variables` + logicQuestion,
      { params }
    );
  }

  post(payload: LogicQuestion): Observable<LogicQuestion> {
    return this.http.post<LogicQuestion>(
      `${environment.apiEndpoint}/logic-variables`,
      { ...payload, id: undefined, conditions: undefined }
    );
  }

  put(payload: LogicQuestion): Observable<LogicQuestion> {
    return this.http.put<LogicQuestion>(
      `${environment.apiEndpoint}/logic-variables`,
      { ...payload, conditions: undefined }
    );
  }

  getLogicCondition(
    logicVariableId: string
  ): Observable<LogicCondition[] | LogicCondition> {
    return this.http.get<any>(
      `${environment.apiEndpoint}/logic-variables/${logicVariableId}/conditions`
    );
  }

  postLogicCondition(
    logicVariableId: string,
    payload: LogicCondition
  ): Observable<LogicCondition> {
    if (Array.isArray(payload.SelectedAnswer)) {
      payload = {
        ...payload,
        SelectedAnswer: payload.SelectedAnswer.join("|"),
      };
    }
    return this.http.post<LogicCondition>(
      `${environment.apiEndpoint}/logic-variables/${logicVariableId}/conditions`,
      { ...payload, ID: undefined }
    );
  }

  putLogicCondition(
    logicVariableId: string,
    payload: LogicCondition
  ): Observable<LogicCondition> {
    if (Array.isArray(payload.SelectedAnswer)) {
      payload = {
        ...payload,
        SelectedAnswer: payload.SelectedAnswer.join("|"),
      };
    }
    return this.http.put<LogicCondition>(
      `${environment.apiEndpoint}/logic-variables/${logicVariableId}/conditions`,
      payload
    );
  }

  deleteCondition(
    logicVariableId: string,
    conditionId: string
  ): Observable<LogicCondition> {
    return this.http.delete<LogicCondition>(
      `${environment.apiEndpoint}/logic-variables/${logicVariableId}/conditions/${conditionId}`
    );
  }

  copyLogicQuestions(
    logicVariables: Array<LogicQuestion>,
    jobTemplateId: string
  ): Observable<LogicQuestion[] | LogicQuestion> {
    return this.http.post<LogicQuestion>(
      `${environment.apiEndpoint}/logic-variables/${jobTemplateId}/copy`,
      logicVariables
    );
  }
}
