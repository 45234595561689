import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Site } from "#models/site.model";
import { Observable } from "rxjs";

@Injectable()
export class SiteService {
  constructor(private http: HttpClient) {}

  getList({ cache = true } = {}): Observable<Site[]> {
    let url = `${environment.apiEndpoint}/sites`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<Site[]>(url, options);
  }

  getById(siteId: number): Observable<Site> {
    return this.http.get<Site>(`${environment.apiEndpoint}/sites/${siteId}`);
  }

  post(payload: Site): Observable<Site> {
    return this.http.post<Site>(`${environment.apiEndpoint}/sites`, payload);
  }

  put(payload: Site): Observable<Site> {
    return this.http.put<Site>(
      `${environment.apiEndpoint}/sites/${payload.ID}`,
      payload
    );
  }
}
