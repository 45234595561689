import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { Variable } from "#models/variables/variable.model";
import { CalculationVariable } from "#models/variables/calculation-variables.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class VariablesService {
  constructor(private http: HttpClient) {}

  getRange(filter: {
    jobTemplateId?: string;
    expand: boolean;
  }): Observable<Array<Variable>> {
    const params = {
      ...(filter &&
        filter.jobTemplateId && { jobTemplateId: filter.jobTemplateId }),
      ...(filter && filter.expand && { expand: filter.expand + "" }),
    };
    return this.http.get<Array<Variable>>(
      `${environment.apiEndpoint}/range-variables`,
      { params }
    );
  }

  getLookups(filter: {
    jobTemplateId?: string;
    activityId?: string;
    lookupId?: string;
    expand?: boolean;
  }): Observable<Array<Variable>> {
    const params = {
      ...(filter &&
        filter.jobTemplateId && { jobTemplateId: filter.jobTemplateId }),
      ...(filter && filter.activityId && { activityId: filter.activityId }),
      ...(filter && filter.lookupId && { lookupId: filter.lookupId }),
      ...(filter && filter.expand && { expand: filter.expand + "" }),
    };
    return this.http.get<Array<Variable>>(
      `${environment.apiEndpoint}/lookup-variables`,
      { params }
    );
  }

  getSingleLookupVariables(filters: {
    jobTemplateId?: string;
    activityId?: string;
    lookupId?: string;
    expand?: boolean;
  }): Observable<Array<Variable>> {
    return this.getLookups(filters).pipe(
      map((array) => array.filter((x) => x.Type === 4))
    );
  }

  getDoubleLookupVariables(filters: {
    jobTemplateId?: string;
    activityId?: string;
    lookupId?: string;
    expand?: boolean;
  }): Observable<Array<Variable>> {
    return this.getLookups(filters).pipe(
      map((array) => array.filter((x) => x.Type === 5))
    );
  }

  getStaticLookupVariables(filters: {
    jobTemplateId?: string;
    activityId?: string;
    lookupId?: string;
    expand?: boolean;
  }): Observable<Array<Variable>> {
    return this.getLookups(filters).pipe(
      map((array) => array.filter((x) => x.Type === 6))
    );
  }

  getMultiplier(filter: {
    jobTemplateId?: string;
    expand?: boolean;
    multiplyVariable?: string;
  }): Observable<Variable[] | Variable> {
    const multiplyVariable =
      filter && filter.multiplyVariable ? "/" + filter.multiplyVariable : "";
    const params =
      multiplyVariable === ""
        ? {
            ...(filter &&
              filter.jobTemplateId && { jobTemplateId: filter.jobTemplateId }),
            ...(filter && filter.expand && { expand: filter.expand + "" }),
          }
        : {};
    return this.http.get<Variable[] | Variable>(
      `${environment.apiEndpoint}/multiply-variables` + multiplyVariable,
      { params }
    );
  }

  getCustom(filter: {
    jobTemplateId: string;
    expand?: boolean;
    multiplyVariable?: string;
  }): Observable<Variable[] | Variable> {
    const customVariable =
      filter && filter.multiplyVariable ? "/" + filter.multiplyVariable : "";

    const params =
      customVariable === ""
        ? {
            ...(filter &&
              filter.jobTemplateId && { jobTemplateId: filter.jobTemplateId }),
            ...(filter && filter.expand && { expand: filter.expand + "" }),
          }
        : {};

    return this.http.get<Variable[] | Variable>(
      `${environment.apiEndpoint}/calculation-variables` + customVariable,
      { params }
    );
  }

  getCalculation(): Observable<CalculationVariable[]> {
    return this.http.get<CalculationVariable[]>(
      `${environment.apiEndpoint}/calculations`
    );
  }

  postRange(payload: Variable): Observable<Variable> {
    return this.http.post<Variable>(
      `${environment.apiEndpoint}/range-variables`,
      { ...payload, ID: undefined, Output: undefined }
    );
  }

  postLookup(payload: Variable): Observable<Variable> {
    return this.http.post<Variable>(
      `${environment.apiEndpoint}/lookup-variables`,
      { ...payload, ID: undefined, Output: undefined }
    );
  }

  postMultiplier(payload: Variable): Observable<Variable> {
    return this.http.post<Variable>(
      `${environment.apiEndpoint}/multiply-variables`,
      { ...payload, ID: undefined, Output: undefined }
    );
  }

  postCustom(payload: Variable, jobTemplateId: string): Observable<Variable> {
    return this.http.post<Variable>(
      `${environment.apiEndpoint}/calculation-variables`,
      { ...payload, ID: undefined, Output: undefined }
    );
  }

  putRange(payload: Variable): Observable<Variable> {
    return this.http.put<Variable>(
      `${environment.apiEndpoint}/range-variables/${payload.ID}`,
      { ...payload }
    );
  }

  putLookup(payload: Variable): Observable<Variable> {
    return this.http.put<Variable>(
      `${environment.apiEndpoint}/lookup-variables/${payload.ID}`,
      { ...payload }
    );
  }

  putMultiplier(payload: Variable): Observable<Variable> {
    return this.http.put<Variable>(
      `${environment.apiEndpoint}/multiply-variables/${payload.ID}`,
      { ...payload }
    );
  }

  updateseqMultiplier(
    jobTemplateId: string
  ): Observable<Variable[] | Variable> {
    return this.http.get<any>(
      `${environment.apiEndpoint}/multiply-variables/ValidateAndUpdateSequence/` + jobTemplateId
    );
  }

  putCustom(payload: Variable, jobTemplateId: string): Observable<Variable> {
    return this.http.put<Variable>(
      `${environment.apiEndpoint}/calculation-variables/${payload.ID}`,
      { ...payload, Output: undefined }
    );
  }

  copy(jobTemplateId: string, variableId: string): Observable<Variable> {
    return this.http.post<Variable>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}/variables/${variableId}/copy`,
      {}
    );
  }

  deleteFromJobTemplate(
    jobTemplateId: string,
    variableId: string
  ): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}/variables/${variableId}/delete`
    );
  }
}
