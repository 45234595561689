import { Tag } from "#models/utilities/tag.model";
import { TagService } from "#services/api/tag.service";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "create-tag",
  templateUrl: "./create-tag.component.html",
})
export class CreateTagComponent implements OnDestroy {
  tagForm: UntypedFormGroup = new UntypedFormGroup({
    Title: new UntypedFormControl(null, Validators.required),
  });

  @Input() visible: boolean = false;

  @Output() tagCreated = new EventEmitter<Tag>();

  private _destroy$ = new Subject<boolean>();
  constructor(
    private readonly _tagSvc: TagService,
    private _messageSvc: MessageService,
    private _translateSvc: TranslateService
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  onHide() {
    this.tagCreated.emit(null);
  }

  onTagSubmit() {
    const payload: Tag = new Tag();
    payload.Title = this.tagForm.get("Title").value;
    this._tagSvc
      .create(payload)
      .pipe(takeUntil(this._destroy$))
      .subscribe((tag: Tag) => {
        this.tagForm.reset();
        this.visible = false;
        this.tagCreated.emit(tag);
        this._messageSvc.add({
          severity: "success",
          summary: this._translateSvc.instant("common.success") + "!",
          detail: this._translateSvc.instant("components.tags.created"),
          life: 5000,
        });
      });
  }
}
