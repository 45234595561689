import { Component, OnInit, OnDestroy } from '@angular/core';
import { Rating } from '#models/rating';
import { RatingService } from '#services/shared/rating.service';
import { LoadingIndicatorService } from '#services/shared';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit, OnDestroy {

    rating: Rating;
    display: boolean;
    private subscription: Subscription;

    constructor(
        public ratingSvc: RatingService,
        public loadingIndicatorSvc: LoadingIndicatorService
    ) { }

    ngOnInit(): void {
        this.rating = new Rating();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    submit() {
        if (!this.rating.applicationReferenceId) {
            throw new Error("Your application must be registered in the RateIT Service");
        }

        this.loadingIndicatorSvc.show();

        const observable = this.ratingSvc.post(this.rating)
            .pipe(finalize(() => this.loadingIndicatorSvc.hide()));

        this.subscription = observable
            .subscribe(() => this.dismiss());
    }

    show() {
        this.display = true;
    }

    dismiss() {
        this.display = false;
    }
}
