export class Feedback {
    Id: number;
    Rev: number;
    Url: string;
    AreaPath: string;
    TeamProject: string;
    IterationPath: string;
    WorkItemType: string;
    State: string;
    Reason: string;
    AssignedTo: string;
    CreatedDate: string;
    CreatedBy: string;
    Title: string;
    Effort: string;
    Description: string;
    Tags: string;
    Priority: string;
    ReproSteps: string;
    History: string;
}
