import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FeedbackService, BusinessUnitsService } from "#services/api";
import { LoadingIndicatorService } from "#services/shared";
import { Feedback, BusinessUnit } from "models";
import { finalize, concatMap } from "rxjs/operators";
import { Subscription } from "rxjs";
import { FeedbackCreateComponent } from "#pages/feedback-create/feedback-create.component";
import { FeedbackEditComponent } from "#pages/feedback-edit/feedback-edit.component";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit, OnDestroy {
  items: Feedback[];
  selectedItem: Feedback;
  businessUnits: BusinessUnit[];
  selectedBusinessUnit: BusinessUnit;
  loadingCompleted: boolean;
  subscription: Subscription;

  @ViewChild("dialog", { static: false }) dialog: FeedbackCreateComponent;
  @ViewChild("editDialog", { static: false }) editDialog: FeedbackEditComponent;

  constructor(
    public feedbackSvc: FeedbackService,
    public businessUnitSvc: BusinessUnitsService,
    public loadingIndicatorSvc: LoadingIndicatorService
  ) {}

  ngOnInit() {
    this.loadInitialData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showDialog() {
    this.dialog.show();
  }

  insertItem(newItem: Feedback) {
    this.items = [...this.items, newItem];
  }

  updateItem(updatedItem: Feedback) {
    const index = this.items.findIndex((i) => i.Id === updatedItem.Id);
    this.items.splice(index, 1, updatedItem);
  }

  onRowSelect(event: any) {
    this.editDialog.show();
  }

  loadFeedback() {
    this.loadingIndicatorSvc.show();
    const observable = this.getFeedbackObservable();
    observable.subscribe((items) => (this.items = items));
  }

  private loadInitialData() {
    this.loadingIndicatorSvc.show();

    const observable = this.businessUnitSvc.getList().pipe(
      concatMap((businessUnits) => {
        this.businessUnits = businessUnits;
        this.selectedBusinessUnit = businessUnits[0];
        return this.getFeedbackObservable();
      })
    );

    this.subscription = observable.subscribe((items) => (this.items = items));
  }

  private getFeedbackObservable() {
    return this.feedbackSvc
      .getListByBusinessUnitName(this.selectedBusinessUnit.Name)
      .pipe(finalize(() => this.setLoadingOff()));
  }

  private setLoadingOff() {
    this.loadingIndicatorSvc.hide();
    this.loadingCompleted = true;
  }
}
