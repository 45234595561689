import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "#services/shared/shared.module";
import { LoadingIndicatorService } from "#services/shared/loading-indicator.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ComponentsModule } from "#components/components.module";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { TableModule } from "primeng/table";
import { LoginComponent } from "./login/login.component";
import { MultiSelectModule } from "primeng/multiselect";
import { FeedbackComponent } from "./feedback/feedback.component";
import { FeedbackEditComponent } from "./feedback-edit/feedback-edit.component";
import { FeedbackCreateComponent } from "./feedback-create/feedback-create.component";
import { RouterModule } from "@angular/router";
import { DropdownModule } from "primeng/dropdown";
import { DialogModule } from "primeng/dialog";
import { InputTextareaModule } from "primeng/inputtextarea";
import { EditorModule } from "primeng/editor";
import { ApiModule } from "#services/api/api.module";
import { FeedbackPriorityPipe } from "helpers/pipes/feedback-priority.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { NgxPrintModule } from 'ngx-print';

@NgModule({
  declarations: [
    LoginComponent,
    FeedbackComponent,
    FeedbackEditComponent,
    FeedbackCreateComponent,
    // Pipes
    FeedbackPriorityPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule,
    RouterModule,

    // PrimeNG
    InputTextModule,
    ButtonModule,
    MultiSelectModule,
    DropdownModule,
    TableModule,
    ButtonModule,
    DialogModule,
    InputTextareaModule,
    EditorModule,

    // App
    RouterModule,

    ApiModule,

    //translate
    TranslateModule,
    NgxPrintModule,
  ],
  providers: 
  [
    LoadingIndicatorService,
    // Pipes
    FeedbackPriorityPipe,
    NgxPrintModule,
  ],
  exports: [
    // Pipes
    FeedbackPriorityPipe,
  ],
})
export class PagesModule {}
