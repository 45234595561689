// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  font-weight: 100;
  margin: 5px 0 !important;
}
section.rounded {
  display: flex;
  border: 1px solid #dbdcdd;
  border-radius: 6px;
  padding: 0 10px;
  float: left;
  width: 160px !important;
}
section span {
  color: black;
}

span.status {
  margin-top: 12px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
span.status.complete {
  background-color: #32ad0c;
}
span.status.pending {
  background-color: white;
  border: 2px solid #f19626;
}
span.status.danger {
  background-color: white;
  border: 2px solid #d44242;
}`, "",{"version":3,"sources":["webpack://./src/components/estimate-status/estimate-status.component.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;EACA,wBAAA;AAAJ;AACI;EACI,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,uBAAA;AACR;AACI;EACI,YAAA;AACR;;AAIE;EACE,gBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,kBAAA;AADJ;AAEI;EACE,yBAAA;AAAN;AAEI;EACE,uBAAA;EACA,yBAAA;AAAN;AAEI;EACE,uBAAA;EACA,yBAAA;AAAN","sourcesContent":["\nsection {\n    font-weight: 100;\n    margin: 5px 0 !important;\n    &.rounded {\n        display: flex;\n        border: 1px solid #dbdcdd;\n        border-radius: 6px;\n        padding: 0 10px;\n        float: left;\n        width: 160px !important;\n    }\n    span {\n        color: black;\n    }\n}\n\nspan {\n  &.status {\n    margin-top: 12px;\n    height: 12px;\n    width: 12px;\n    border-radius: 50%;\n    display: inline-block;\n    margin-right: 10px;\n    &.complete {\n      background-color: #32ad0c;\n    }\n    &.pending {\n      background-color: white;\n      border: 2px solid #f19626;\n    }\n    &.danger {\n      background-color: white;\n      border: 2px solid #d44242;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
