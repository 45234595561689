// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  display: block;
}

input, textarea {
  display: block;
  width: 100%;
}

textarea {
  height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/pages/feedback-edit/feedback-edit.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":["label { \n    display: block;\n}\n\ninput, textarea {\n    display: block;\n    width: 100%;\n}\n\ntextarea {\n    height: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
