import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Question } from "#models/job-templates/section";
import { UntypedFormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-yes-no",
  templateUrl: "./yes-no.component.html",
  styleUrls: ["./yes-no.component.scss"],
})
export class YesNoComponent implements OnInit, OnDestroy {
  @Input() question: Question;
  @Input() control: UntypedFormControl;
  @Output() event = new EventEmitter();

  options: Array<{ name: string; key: string }> = [
    { name: "Yes", key: "Yes" },
    { name: "No", key: "No" },
  ];
  selectedValue: { name: string; key: string };
  private destroy$ = new Subject<boolean>();

  ngOnInit() {
    this.selectedValue = this.options.find(
      (o) => o.key === this.question.Answer
    );
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value === null) {
          this.selectedValue = null;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onChange(value: { name: string; key: string }) {
    this.control.patchValue(value.key);
  }
}
