import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { IDowntime } from "#models/event/downtime.model";
import { Observable, shareReplay } from "rxjs";

@Injectable()
export class DowntimeService {
  private downtimes$ = this.http
    .get<Array<IDowntime>>(`${environment.apiEndpoint}/downtimes`)
    .pipe(shareReplay(1));
  constructor(private http: HttpClient) {}

  getList(): Observable<Array<IDowntime>> {
    return this.downtimes$;
  }

  get(downtimeID?: number): Observable<Array<IDowntime> | IDowntime> {
    const downtime = downtimeID ? "/" + downtimeID : "";
    return this.http.get<Array<IDowntime> | IDowntime>(
      `${environment.apiEndpoint}/downtimes` + downtime
    );
  }

  post(payload: any): Observable<IDowntime> {
    return this.http.post<IDowntime>(
      `${environment.apiEndpoint}/downtimes`,
      payload
    );
  }

  put(downtimeId: number, payload: any): Observable<IDowntime> {
    return this.http.put<IDowntime>(
      `${environment.apiEndpoint}/downtimes/${downtimeId}`,
      payload
    );
  }

  getByEvent(payload: any): Observable<Array<IDowntime>> {
    const params = new HttpParams().set("eventId", payload);
    return this.http.get<Array<IDowntime>>(
      `${environment.apiEndpoint}/downtimes`,
      {
        params,
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      }
    );
  }

  getWorklistItems(downtimeId: number) {
    return this.http.get<Array<any>>(
      `${environment.apiEndpoint}/downtimes/${downtimeId}/worklist-items`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  applyJobTemplate(
    itemId: number,
    jobTemplateID: string,
    versionID: string,
    customName: string,
    ProductivityCalendar: number,
    AssignedScheduler: number
  ) {
    return this.http.post<Array<any>>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/apply`,
      {
        jobTemplateID,
        versionID,
        customName,
        ProductivityCalendar,
        AssignedScheduler,
      }
    );
  }

  detachWorkListItem(itemId: number) {
    return this.http.post(
      `${environment.apiEndpoint}/worklist-items/${itemId}/disable`,
      null,
      {
        responseType: "text",
      }
    );
  }

  detachJobTemplate(itemId: number, appliedJobTemplateId: number) {
    return this.http.post(
      `${environment.apiEndpoint}/worklist-items/${itemId}/detach/${appliedJobTemplateId}`,
      null,
      {
        responseType: "text",
      }
    );
  }

  getSalesforce(site: string): Observable<Array<ISalesForceDowntime>> {
    const params = new HttpParams().set("siteId", site);
    return this.http.get<Array<any>>(
      `${environment.apiEndpoint}/salesforce/downtimes`,
      { params }
    );
  }

  getSalesforceID(site: number, workListName: string) {
    return this.http.get<ISalesForceDowntime>(
      `${environment.apiEndpoint}/salesforce/downtimes/${site}/${workListName}/getSalesForceWorkListID`
    );
  }

  postSalesforce(
    downtime: string,
    eventId: number,
    siteId: number,
    title: string,
    refresh: boolean = false
  ) {
    return this.http.post<Array<any>>(
      `${environment.apiEndpoint}/salesforce/downtimes/${downtime}/import?eventId=${eventId}&siteId=${siteId}&downtimeName=${title}&refresh=${refresh}`,
      {
        eventId,
        siteId,
        downtimeName: title,
        downtimeTitle: downtime,
        refresh: refresh,
      }
    );
  }

  getLatestWorkItemListCount(
    downtime: string,
    siteId: number
  ): Observable<number> {
    const params = new HttpParams()
      .set("siteId", siteId)
      .set("downtimeName", downtime);

    return this.http.get<number>(
      `${environment.apiEndpoint}/salesforce/downtimes/checkdtwlcount`,
      { params }
    );
  }
}

export interface ISalesForceDowntime {
  DowntimeTitle: string;
  IM_Downtime_Id__c: string;
  IM_Downtime_Title__c: string;
  Name: string;
  id: string;
}
