import { ExcelLookupData } from "#models/lookups/excel-lookup-data.model";
import { RangeLookupData } from "#models/lookups/range-lookup-data.model";
import { Injectable } from "@angular/core";
import * as Excel from "exceljs";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  async importFromFile(bstr: string | ArrayBuffer): Promise<ExcelLookupData[]> {
    const data: ExcelLookupData[] = [];
    const wb = new Excel.Workbook();
    const workBook = await wb.xlsx.load(bstr as Buffer);
    workBook.eachSheet((sheet, id) => {
      const columnNames = new Map<number, string>();
      sheet.eachRow((row, rowIndex) => {
        if (rowIndex === 1) {
          const firstRow = (row.values as string[]).slice(1);
          for (const [index, column] of firstRow.entries()) {
            columnNames.set(index + 1, column);
          }
        }

        if (rowIndex > 1) {
          const newObj = new ExcelLookupData();
          row.eachCell((cell, colNum) => {
            if (rowIndex > 1) {
              newObj[columnNames.get(colNum)] = cell.value;
            }
          });
          data.push(newObj);
        }
      });
    });

    return data;
  }
}
