import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Rating } from '#models/rating';
import { share } from 'rxjs/operators';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
@Injectable()
export class RatingService {

    constructor(
        private http: HttpClient
    ) { }

    public post(rating: Rating): Observable<any> {
        return this.http
            .post(`${environment.rateItEndpoint}/odata/ApplicationRatings`, rating)
            .pipe(share());
    }
}