import { IUserRole } from "helpers/interfaces/user/user-role.interface";
import { BusinessUnit } from "./business-unit";

export class User {
  ID: number;
  FirstName: string;
  LastName: string;
  Email: string;
  CAI: string;
  FullName: string;
  DisclaimerAccepted: boolean;
  BusinessUnit?: BusinessUnit;
  BusinessUnitID?: number;
  IsSystemAdmin?: boolean;
  IsDisabled?: boolean;
  Role?: Array<IUserRole>;
}
