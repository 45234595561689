import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Feedback, BusinessUnit } from 'models';
import { FeedbackService } from '#services/api';
import { LoadingIndicatorService } from '#services/shared';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { FeedbackType, FeedbackTypeEnum } from '#models/enums/feedback/feedback-type';
import { FeedbackPriority, FeedbackPriorityEnum } from '#models/enums/feedback/feedback-priority';

@Component({
    selector: 'app-feedback-edit',
    templateUrl: './feedback-edit.component.html',
    styleUrls: ['./feedback-edit.component.scss']
})
export class FeedbackEditComponent implements OnInit, OnDestroy {
    @Input() feedback: Feedback;
    @Input() businessUnit: BusinessUnit;
    @Output() onUpdate = new EventEmitter<Feedback>();

    private subscription: Subscription;
    types: SelectItem[];
    priorities: SelectItem[];
    visible: boolean;

    get header() { return this.feedback ? `Work Item - ${this.feedback.Id}` : ''; }

    constructor(
        public feedbackSvc: FeedbackService,
        public loadingIndicatorSvc: LoadingIndicatorService
    ) { }

    ngOnInit() {
        this.types = Object.keys(FeedbackType)
            .filter(key => typeof FeedbackType[key] === 'number')
            .map(key => FeedbackTypeEnum.map.get(FeedbackType[key]))
            .map(desc => (<SelectItem>{ value: desc, label: desc }));

        this.priorities = Object.keys(FeedbackPriority)
            .filter(key => typeof FeedbackPriority[key] === "number")
            .map(key => FeedbackPriority[key])
            .map(item => (<SelectItem>{ value: item.toString(), label: FeedbackPriorityEnum.map.get(item) }));
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }

    save() {
        this.loadingIndicatorSvc.show();
        this.feedback.Tags = this.businessUnit.Name;

        const observable = this.feedbackSvc
            .update(this.feedback)
            .pipe(finalize(() => this.loadingIndicatorSvc.hide()))

        this.subscription = observable.subscribe(updatedItem => {
            this.feedback = updatedItem;
            this.onUpdate.emit(updatedItem);
        });
    }
}
