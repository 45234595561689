import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Question } from "#models/job-templates/section";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-numeric",
  templateUrl: "./numeric.component.html",
})
export class NumericComponent {
  @Input()
  question: Question;
  @Output()
  event = new EventEmitter();
  @Input()
  control: UntypedFormControl;
}
