import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { Question } from "#models/job-templates/section";
import { QuestionTypes } from "helpers/enums/questionTypes.enum";
import { UntypedFormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { YesNoComponent } from "./yes-no/yes-no.component";
import { NumericComponent } from "./numeric/numeric.component";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { TextComponent } from "./text/text.component";
const componentsMap = {
  [QuestionTypes.YESNO]: YesNoComponent,
  [QuestionTypes.NUMERIC]: NumericComponent,
  [QuestionTypes.DROPDOWN]: DropdownComponent,
  [QuestionTypes.TEXT]: TextComponent,
};

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html",
  styleUrls: ["./question.component.scss"],
})
export class QuestionComponent implements OnInit, OnDestroy {
  private _question: Question;
  @Input()
  set question(question: Question) {
    this._question = question;
  }
  get question(): Question {
    return this._question;
  }

  @Input() index: number;
  @Input() control: UntypedFormControl;
  @Output() revisionSelected: EventEmitter<string> = new EventEmitter();
  @ViewChild("factoryContainer", { static: true, read: ViewContainerRef })
  container: ViewContainerRef;

  private destroy$ = new Subject<boolean>();

  ngOnInit(): void {
    this._loadComponent(this.question);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private _loadComponent(question: Question): void {
    this.container.clear();
    const componentRef = this.container.createComponent(
      componentsMap[question.Type]
    );
    componentRef.instance["control"] = this.control;
    componentRef.instance["question"] = this.question;
  }

  openRevision(revision: string): void {
    this.revisionSelected.emit(revision);
  }
}
