import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { JobTemplate } from "#models/job-templates/job-template";
import { IJobTemplateVersion } from "helpers/interfaces/job-template-version.interface";
import { Observable } from "rxjs";

@Injectable()
export class JobTemplateVersionService {
  constructor(private http: HttpClient) {}

  get(jobTemplateId: string): Observable<Array<IJobTemplateVersion>> {
    return this.http.get<Array<IJobTemplateVersion>>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}/versions`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  post(
    jobTemplateId: string,
    name: string,
    answers: Array<{ value: string; questionID: string }>
  ): Observable<IJobTemplateVersion> {
    return this.http.post<IJobTemplateVersion>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}/versions`,
      {
        name,
        answers,
      }
    );
  }

  delete(
    jobTemplateId: string,
    versionId: string
  ): Observable<Array<IJobTemplateVersion>> {
    return this.http.delete<Array<IJobTemplateVersion>>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}/versions/${versionId}`
    );
  }

  copy(jobTemplateId: string, versionId: string): Observable<JobTemplate> {
    return this.http.post<JobTemplate>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}/versions/${versionId}/copy`,
      {}
    );
  }

  put(
    jobTemplateId: string,
    name: string,
    answers: Array<{ value: string; questionID: string }>,
    versionId?: string
  ): Observable<IJobTemplateVersion> {
    return this.http.put<IJobTemplateVersion>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}/versions/${versionId}`,
      {
        name,
        answers,
        id: versionId,
      }
    );
  }
}
