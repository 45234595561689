import { Component, DestroyRef, inject, Input, OnInit } from "@angular/core";
import { WorlistItemService } from "#services/api/worklist-item.service";
import { ActivityMetrics } from "#models/activities/activity.model";
import { takeUntil } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
@Component({
  selector: "app-overriden-activities-info",
  templateUrl: "./overriden-activities-info.component.html",
  styleUrls: ["./overriden-activities-info.component.scss"],
})
export class OverridenActivitiesInfoComponent implements OnInit {
  @Input()
  _worklistItemid: string;
  _appliedjobtemplateID: string;

  _NormsNonOverridenPercentage: number;
  worklistItemActivitiesMetrics: ActivityMetrics;
  destroyRef = inject(DestroyRef);
  private _messageService: any;

  constructor(private readonly _worklistItemSvc: WorlistItemService) {}

  @Input()
  private set WorklistItemid1(worklistItemid: string) {
    this._worklistItemid = worklistItemid;
  }

  @Input()
  private set AppliedjobtemplateID(appliedjobtemplateID: string) {
    this._appliedjobtemplateID = appliedjobtemplateID;
  }

  ngOnInit() {
    this.getInfo(this._worklistItemid, this._appliedjobtemplateID);
  }

  private get NormsNonOverridenPercentage(): number {
    return this._NormsNonOverridenPercentage;
  }

  getInfo(worklistItemid: string, appliedjobtemplateID: string): void {
    this._worklistItemSvc
      .getWorklistItemActivitiesMetrics(worklistItemid, appliedjobtemplateID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((activityMetrics) => {
        this.worklistItemActivitiesMetrics = activityMetrics;
        this._NormsNonOverridenPercentage =
          activityMetrics.OverridenActivitiesPercentage;
      });
  }
}
