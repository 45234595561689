import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Question } from "#models/job-templates/section";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-text",
  templateUrl: "./text.component.html",
})
export class TextComponent {
  @Input()
  question: Question;
  @Output()
  event = new EventEmitter();
  @Input()
  control: UntypedFormControl;
}
