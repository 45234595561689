import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Facility } from "#models/index";
import { Observable } from "rxjs";

@Injectable()
export class FacilityService {
  constructor(private http: HttpClient) {}

  public getAllBySubArea(subAreaId: number): Observable<Facility[]> {
    return this.http.get<Facility[]>(
      `${environment.apiEndpoint}/facility/getBySubArea/${subAreaId}`
    );
  }

  public getFacilityById(id: number): Observable<Facility> {
    return this.http.get<Facility>(`${environment.apiEndpoint}/facility/${id}`);
  }

  public createFacility(facility: Facility): Observable<Facility> {
    return this.http.post<Facility>(
      `${environment.apiEndpoint}/facility`,
      facility
    );
  }

  public updateFacility(facility: Facility): Observable<Facility> {
    return this.http.put<Facility>(
      `${environment.apiEndpoint}/facility`,
      facility
    );
  }

  public deleteFacility(id: number): Observable<Facility> {
    return this.http.put<Facility>(
      `${environment.apiEndpoint}/facility/delete/${id}`,
      null
    );
  }
}
