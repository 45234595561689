import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Event } from "#models/event/event.model";
import { Site } from "#models/site.model";
import { Observable } from "rxjs";

@Injectable()
export class EventService {
  constructor(private http: HttpClient) {}

  getList(payload?: Event): Observable<Event[]> {
    return this.http.get<Event[]>(`${environment.apiEndpoint}/events`, {
      params: {
        ...(payload?.BusinessUnitID && {
          BusinessUnitID: payload.BusinessUnitID + "",
        }),
        ...(payload?.SiteID && { SiteID: payload.SiteID + "" }),
      },
      headers: new HttpHeaders({
        "Cache-control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      }),
    });
  }

  get(eventID?: number): Observable<Event[] | Event> {
    const event = eventID ? "/" + eventID : "";
    return this.http.get<Event[] | Event>(
      `${environment.apiEndpoint}/events` + event
    );
  }

  post(payload: Event): Observable<Event> {
    const site = payload.SiteID as Site;
    return this.http.post<Event>(`${environment.apiEndpoint}/events`, {
      ...payload,
      ID: undefined,
      SiteID: site.ID,
    });
  }

  put(payload: Event): Observable<Event> {
    const site = payload.SiteID as Site;
    return this.http.put<Event>(
      `${environment.apiEndpoint}/events/${payload.ID}`,
      { ...payload, SiteID: site.ID }
    );
  }

  delete(payload: Event): Observable<Event> {
    const site = payload.SiteID as Site;
    payload.Disabled = true;
    return this.http.put<Event>(
      `${environment.apiEndpoint}/events/${payload.ID}`,
      { ...payload, SiteID: site.ID }
    );
  }
}
