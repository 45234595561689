export enum FeedbackType {
    Bug = 0,
    UserStory = 1
}

export class FeedbackTypeEnum {
    static map = new Map<number, string>([
        [FeedbackType.Bug, "Bug"], 
        [FeedbackType.UserStory, "User Story"]
    ]);
}