import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { BusinessUnitsComponent } from "#pages/admin/business-units/business-units.component";

import { PagesModule } from "#pages/pages.module";
import { AdminPagesModule } from "#pages/admin/admin.pages.module";
import { NotFoundComponent } from "#pages/error/not-found/not-found.component";
import { ErrorPagesModule } from "#pages/error/error.pages.module";
import { GenericErrorComponent } from "#pages/error/generic-error/generic-error.component";
import { DBUnavailableComponent } from "#pages/error/db-unavailable/db-unavailable.component";
import { NotAuthorizedComponent } from "#pages/error/not-authorized/not-authorized.component";
import { IsApplicationUser } from "./app.routing.guards";
import { LoginComponent } from "#pages/login/login.component";

import { FeedbackComponent } from "#pages/feedback/feedback.component";
import { UserRoleEnum } from "helpers/enums/userRole.enum";

import { TranslateModule } from "@ngx-translate/core";

const routes: Route[] = [
  // Home page and slash-appender
  { path: "", redirectTo: "/login", pathMatch: "full" },
  // Pages
  {
    path: "",
    canActivate: [IsApplicationUser],
    children: [
      {
        path: "events",
        loadChildren: () =>
          import("./../pages/events/events.module").then((m) => m.EventsModule),
        data: {
          roles: [
            UserRoleEnum.SiteManager,
            UserRoleEnum.EventCreator,
            UserRoleEnum.Viewer,
            UserRoleEnum.Estimator,
          ],
        },
      },
      {
        path: "downtimes",
        loadChildren: () =>
          import("./../pages/downtimes/downtimes.module").then(
            (m) => m.DowntimesModule
          ),
        data: {
          roles: [
            UserRoleEnum.SiteManager,
            UserRoleEnum.Viewer,
            UserRoleEnum.Estimator,
            UserRoleEnum.EventCreator,
          ],
        },
      },
      {
        path: "worklist-item",
        loadChildren: () =>
          import("./../pages/worklist-item/worklist-item.module").then(
            (m) => m.WorklistItemModule
          ),
        data: {
          roles: [
            UserRoleEnum.SiteManager,
            UserRoleEnum.Viewer,
            UserRoleEnum.Estimator,
          ],
        },
      },
      { path: "feedback", component: FeedbackComponent },
      {
        path: "lookups",
        loadChildren: () =>
          import("./../pages/lookups-search/lookups-search.module").then(
            (m) => m.LookupsSearchModule
          ),
        data: {
          roles: [UserRoleEnum.Viewer],
        },
      },
    ],
  },

  // Admin pages
  {
    path: "admin",
    canActivate: [IsApplicationUser],
    children: [
      {
        path: "business-units",
        component: BusinessUnitsComponent,
        data: {
          roles: [
            UserRoleEnum.SiteManager,
            UserRoleEnum.EventCreator,
            UserRoleEnum.Estimator,
            UserRoleEnum.Viewer,
          ],
        },
      },
      {
        path: "sites",
        loadChildren: () =>
          import("./../pages/admin/sites/sites.module").then(
            (m) => m.SitesModule
          ),
        data: {
          roles: [
            UserRoleEnum.SiteManager,
            UserRoleEnum.EventCreator,
            UserRoleEnum.Estimator,
            UserRoleEnum.Viewer,
          ],
        },
      },
      {
        path: "trades",
        loadChildren: () =>
          import("./../pages/admin/trades/trades.module").then(
            (m) => m.TradesModule
          ),
        data: {
          roles: [
            UserRoleEnum.SiteManager,
            UserRoleEnum.EventCreator,
            UserRoleEnum.Estimator,
            UserRoleEnum.Viewer,
          ],
        },
      },
      {
        path: "job-templates",
        loadChildren: () =>
          import("./../pages/admin/job-templates/job-templates.module").then(
            (m) => m.JobTemplatesModule
          ),
        data: {
          roles: [
            UserRoleEnum.SiteManager,
            UserRoleEnum.EventCreator,
            UserRoleEnum.Estimator,
            UserRoleEnum.Viewer,
          ],
        },
      },
      {
        path: "plants",
        loadChildren: () =>
          import("./../pages/admin/plants/plants.module").then(
            (m) => m.PlantsModule
          ),
        data: {
          roles: [
            UserRoleEnum.SiteManager,
            UserRoleEnum.EventCreator,
            UserRoleEnum.Estimator,
            UserRoleEnum.Viewer,
          ],
        },
      },
      {
        path: "equipment-types",
        loadChildren: () =>
          import("./../pages/admin/equipment-type/equipment-type.module").then(
            (m) => m.EquipmentTypeModule
          ),
        data: {
          roles: [UserRoleEnum.SiteManager, UserRoleEnum.Viewer],
        },
      },
      {
        path: "users",
        loadChildren: () =>
          import("./../pages/admin/user/user.module").then((m) => m.UserModule),
        data: {
          roles: [],
        },
      },
      /* Disabled per US4011148.
        See src/app/header/header.component.ts to re-enable.
      {
        path: "projects",
        loadChildren: () =>
          import("./../pages/admin/projects/projects.module").then((m) => m.ProjectsModule),
        data: {
          roles: [],
        },
      }, */
      {
        path: "lookups",
        loadChildren: () =>
          import("./../pages/admin/lookups/lookups.module").then(
            (m) => m.LookupsModule
          ),
        data: {
          roles: [
            UserRoleEnum.SiteManager,
            UserRoleEnum.EventCreator,
            UserRoleEnum.Estimator,
          ],
        },
      },
    ],
  },

  // "Awaiting login" page
  {
    path: "login",
    component: LoginComponent,
  },

  // Error pages
  {
    path: "error",
    children: [
      { path: "not-found", component: NotFoundComponent },
      { path: "internal-error", component: GenericErrorComponent },
      { path: "not-authorized", component: NotAuthorizedComponent },
      { path: "db-unavailable", component: DBUnavailableComponent },
    ],
  },

  // Displays 404 "Not Found" error page for all unrecognized router.
  // Instead of redirecting, renders context to preserve original "incorrect" url
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    PagesModule,
    ErrorPagesModule,
    AdminPagesModule,
    TranslateModule,
  ],
  exports: [RouterModule, TranslateModule],
})
export class AppRoutingModule {}
