import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Subject, Observable } from "rxjs";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { equipmentClass } from "./equipment-class-template.data";

@Component({
  selector: "app-equipment-class-template",
  templateUrl: "./equipment-class-template.component.html",
  styleUrls: ["./equipment-class-template.component.scss"],
})
export class EquipmentClassTemplateComponent implements OnInit, OnDestroy {
  @Input() show: Observable<boolean>;
  @Output() put: EventEmitter<any> = new EventEmitter();
  title: string;
  display = false;
  equipmentClass = equipmentClass;
  selectedSalesforce: Array<any> = [];

  downtimesForm = new UntypedFormGroup({
    Site: new UntypedFormControl(null),
    Plant: new UntypedFormControl(null),
  });

  private destroy$ = new Subject<boolean>();

  ngOnInit(): void {
    this.show.subscribe((isShow) => {
      this.display = isShow;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  cancel() {
    this.display = false;
  }
}
