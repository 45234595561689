import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Organization } from "#models/organization";
import { OrganizationUser } from "#models/organizationUser";
import { Observable } from "rxjs";

@Injectable()
export class OrganizationService {
  private returnData: any;

  constructor(private http: HttpClient) {}

  getOrganizations(businessUnitId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `${environment.apiEndpoint}/organizations/${businessUnitId}/users`
    );
  }

  public getUserOrgs(businessUnitId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `${environment.apiEndpoint}/organization/currentUserOrgs/${businessUnitId}`
    );
  }

  public getOrganization(orgId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `${environment.apiEndpoint}/organization/${orgId}`
    );
  }

  public addOrganization(
    businessUnitId: number,
    organizationName: string
  ): Observable<Organization> {
    const bodyData = {
      Name: organizationName,
      BusinessUnitId: businessUnitId,
    };
    return this.http.post<Organization>(
      `${environment.apiEndpoint}/organization`,
      bodyData
    );
  }

  public addOrganizationUser(
    orgUsr: OrganizationUser
  ): Observable<OrganizationUser> {
    return this.http.post<OrganizationUser>(
      `${environment.apiEndpoint}/organization/addOrganizationUser`,
      orgUsr
    );
  }

  public removeOrganizationUser(id: number): Observable<OrganizationUser> {
    return this.http.put<OrganizationUser>(
      `${environment.apiEndpoint}/organization/removeOrganizationUser/${id}`,
      null
    );
  }

  public updateOrganizationUser(
    orgUsr: OrganizationUser
  ): Observable<OrganizationUser> {
    return this.http.put<OrganizationUser>(
      `${environment.apiEndpoint}/organization/updateOrganizationUser`,
      orgUsr
    );
  }
}
