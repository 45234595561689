import { NgxPrintModule } from "ngx-print";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpBackend,
} from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import {
  AppComponent,
  HeaderComponent,
  FooterComponent,
  LoadingIndicatorComponent,
  FeedbackComponent,
  VersionComponent,
  QuickLinksComponent,
} from "./";
import { AppRoutingModule } from "./app.routing.module";
import { LoadingIndicatorService } from "#services/shared";
import { SharedModule } from "#services/shared/shared.module";
import { ComponentsModule } from "#components/components.module";
import { of } from "rxjs";
import {
  AuthInterceptor,
  DatetimeParseInterceptor,
} from "#services/interceptors";
import { QuestionModule } from "#components/question/question.module";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { AccessControlModule } from "helpers/directives/access-control/access-control.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PageSpotService } from "#services/shared/pageSpot.service";

import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CommonModule } from "@angular/common";

import { DropdownModule } from "primeng/dropdown";
import { SelectButtonModule } from "primeng/selectbutton";

import { ProgressSpinnerModule } from "primeng/progressspinner";
import { DividerModule } from "primeng/divider";

import {
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MsalModule,
  MsalInterceptor,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalService,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import {
  LogLevel,
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  InteractionType,
} from "@azure/msal-browser";
import { environment } from "#environments/environment";
import { UsageTrackingModule } from "usage-tracking";

export function HttpLoaderFactory(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log({ message });
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.clientId, // PPE testing environment
      authority: "https://login.windows.net/chevron.onmicrosoft.com", // PPE testing environment.
      redirectUri: window.location.origin,
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    "https://graph.microsoft.com/v1.0/me",
    environment.msalConfig.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.msalConfig.scopes,
    },
    loginFailedRoute: "/login-failed",
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoadingIndicatorComponent,
    FeedbackComponent,
    VersionComponent,
    QuickLinksComponent,
  ],
  imports: [
    ProgressSpinnerModule,
    DropdownModule,
    SelectButtonModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    QuestionModule,
    ToastModule,
    AccessControlModule,
    BrowserAnimationsModule,
    DividerModule,
    NgxPrintModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    MsalModule,
    UsageTrackingModule.forRoot({
      connectionString: environment.appInsights.connectionString,
      applicationName: "Norms Estimating",
    }),
  ],
  exports: [CommonModule, TranslateModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    NgxPrintModule,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DatetimeParseInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,

      deps: [TranslateService],
      useFactory: (translate: TranslateService) => {
        return () => {
          translate.use("en").subscribe(
            () => {
              console.info(`Successfully initialized language EN.'`);
            },
            (err) => {
              console.error(`Problem with language initialization.'`);
            },
            () => {}
          );
          return of().toPromise();
        };
      },
      multi: true,
    },
    LoadingIndicatorService,
    MessageService,
    PageSpotService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
