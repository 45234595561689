import { Question } from "#models/job-templates/section";

export class LogicQuestion {
  ID: string;
  Name: string;
  Output: boolean;
  Conditions: Array<Condition>;
}

export class Condition {
  LogicVariableID: string;
  QuestionID: string;
  SelectedAnswer: string;
  ID: string;
  Disabled: boolean;
  Question: Question;
}

export class SmartActivityId {
  Type: string;
  Designation: string;
  TurnAroundPhase: string;
  ID: string;
  StartNumber: number;
  EndNumber: number;
}
