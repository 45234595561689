// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  position: relative;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 99999;
}

.overlay-content {
  position: absolute;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  color: #555;
}

.loadingText {
  font-size: large;
}`, "",{"version":3,"sources":["webpack://./src/app/loading-indicator/loading-indicator.component.scss"],"names":[],"mappings":"AAgBA;EACE,kBAAA;AAfF;;AAiBA;EACE,eAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,0CAAA;EACA,cAAA;AAdF;;AAgBA;EACE,kBAAA;EACA,2BAAA;EACA,mCAAA;EACA,+BAAA;EACA,QAAA;EACA,OAAA;EACA,QAAA;EACA,kBAAA;EACA,WAAA;AAbF;;AAgBA;EACE,gBAAA;AAbF","sourcesContent":["// .loading-indicator img {\n//     $img-size: 196px;\n\n//     position: fixed;\n//     top: 50%;\n//     left: 50%;\n\n//     border: 1px solid gray;\n\n//     margin-top: -$img-size / 2;  // -half of height\n//     margin-left: -$img-size / 2; // -half of width\n\n//     width: $img-size;\n//     height: $img-size;\n// }\n\n.content {\n  position: relative;\n}\n.overlay {\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 2;\n  background-color: rgba(255, 255, 255, 0.8);\n  z-index: 99999;\n}\n.overlay-content {\n  position: absolute;\n  transform: translateY(-50%);\n  -webkit-transform: translateY(-50%);\n  -ms-transform: translateY(-50%);\n  top: 50%;\n  left: 0;\n  right: 0;\n  text-align: center;\n  color: #555;\n}\n\n.loadingText{\n  font-size: large;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
