import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { QuestionDependency } from "#models/job-templates/question-dependency.model";
import { Observable } from "rxjs";

@Injectable()
export class QuestionDependencyService {
  constructor(private http: HttpClient) {}

  get(questionId: string): Observable<Array<QuestionDependency>> {
    return this.http.get<Array<QuestionDependency>>(
      `${environment.apiEndpoint}/questions/${questionId}/dependencies`
    );
  }

  post(payload: QuestionDependency): Observable<QuestionDependency> {
    return this.http.post<QuestionDependency>(
      `${environment.apiEndpoint}/questions/${payload.InferiorQuestionID}/dependencies/${payload.SuperiorQuestionID}`,
      { ...payload, helper: undefined }
    );
  }

  put(payload: QuestionDependency): Observable<QuestionDependency> {
    return this.http.put<QuestionDependency>(
      `${environment.apiEndpoint}/questions/${payload.InferiorQuestionID}/dependencies/${payload.SuperiorQuestionID}`,
      { ...payload, helper: undefined }
    );
  }

  delete(
    inferiorQuestionId: string,
    superiorQuestionId: string
  ): Observable<QuestionDependency> {
    return this.http.delete<QuestionDependency>(
      `${environment.apiEndpoint}/questions/${inferiorQuestionId}/dependencies/${superiorQuestionId}`
    );
  }
}
