import { NgModule } from "@angular/core";
import { BusinessUnitsService, FeedbackService } from "./";
import { SharedModule } from "#services/shared/shared.module";
import { UserService } from "#services/api/user.service";
import { SiteService } from "./site.service";
import { EventService } from "./event.service";
import { SystemService } from "./system.service";
import { UnitsService } from "./units.service";
import { PlantsService } from "./plants.service";
import { RatingService } from "#services/shared/rating.service";
import { DowntimeService } from "./downtime.service";
import { JobTemplatesService } from "./job-template.service";
import { EquipmentTypeService } from "./equipment-type.service";
import { QuestionService } from "./question.service";
import { TradesService } from "./trades.service";
import { SectionsService } from "./sections.service";
import { QuestionOptionService } from "./question-option.service";
import { QuestionDependencyService } from "./question-dependency.service";
import { VariablesService } from "./variables.service";
import { LogicQuestionService } from "./logic-question.service";
import { SmartActivityService } from "./smart-activity.service";
import { ActivityService } from "./activity.service";
import { WorlistItemService } from "./worklist-item.service";
import { JustificationService } from "./justification.service";
import { JobTemplateVersionService } from "./job-template-version.service";
import { PrimaveraService } from "./primavera.service";
import { TagService } from "./tag.service";

@NgModule({
    declarations: [],
    imports: [SharedModule],
    providers: [
        UnitsService,
        SystemService,
        EventService,
        SiteService,
        BusinessUnitsService,
        FeedbackService,
        UserService,
        PlantsService,
        RatingService,
        DowntimeService,
        JobTemplatesService,
        EquipmentTypeService,
        QuestionService,
        TradesService,
        SectionsService,
        QuestionOptionService,
        QuestionDependencyService,
        VariablesService,
        LogicQuestionService,
        ActivityService,
        WorlistItemService,
        JustificationService,
        JobTemplateVersionService,
        PrimaveraService,
        SmartActivityService,
        TagService
    ],
})
export class ApiModule {}
