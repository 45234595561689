import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AuthService } from "#services/shared";
import { RatingComponent } from "#components/rating/rating.component";
import { LoggedUser } from "#models/logged-user";
import { TranslateService } from "@ngx-translate/core";

interface ILanguage {
  name: string;
  code: string;
  fullName: string;
}

interface MenuItem {
  title: string;
  url: string;
  selected?: boolean;
  admin?: boolean;
  viewerUrl?: string;
  items?: Array<MenuItem>;
  allowAccess?: string;
  viewerMode?: true;
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, DoCheck {
  languages: ILanguage[];

  selectedLang: ILanguage;

  currentUser: LoggedUser;
  userRoles: Array<number> = [];
  isSysAdmin: boolean;

  menu: Array<MenuItem> = [
    {
      title: "components.header.events",
      url: "/events",
      allowAccess: "1,2,3,4",
    },
    {
      title: "components.header.downtimes",
      url: "/downtimes",
      allowAccess: "1,2,3,4",
    },
    {
      title: "components.header.lookups",
      url: "/admin/lookups",
      viewerUrl: "/lookups",
      allowAccess: "1,2,3,4",
      viewerMode: true,
    },
    {
      title: "components.header.administration",
      url: null,
      admin: true,
      items: [
        {
          title: "components.header.businessUnits",
          url: "/admin/business-units",
          allowAccess: "1,2,3",
        },
        {
          title: "components.header.users",
          url: "/admin/users",
          admin: true,
        },
        /* Disabled per US4011148.
        NOTE:  Path/Routing disabled in src/app/app.routing.module.ts!!!!
        {
          title: "projects",
          url: "/admin/projects",
          admin: true,
        }, */
        {
          title: "components.header.sites",
          url: "/admin/sites",
          allowAccess: "1,2,3",
        },
        {
          title: "components.header.trades",
          url: "/admin/trades",
          allowAccess: "1,2,3",
        },
        {
          title: "components.header.jobTemplates",
          url: "/admin/job-templates",
          allowAccess: "1,2,3",
        },
        /* {
                title: 'equipment types',
                url: '/admin/equipment-types'
              }, */
        // {
        //   title: 'plants',
        //   url: '/admin/plants'
        // }
      ],
    },
  ];

  @ViewChild("rating") rating: RatingComponent;

  constructor(
    private translate: TranslateService,
    private readonly _authService: AuthService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this.languages = [
      {
        name: "https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg",
        code: "en",
        fullName: "English",
      },
      {
        name: "https://purecatamphetamine.github.io/country-flag-icons/3x2/RU.svg",
        code: "ru",
        fullName: "Russian",
      },
      {
        name: "https://purecatamphetamine.github.io/country-flag-icons/3x2/KZ.svg",
        code: "kz",
        fullName: "Kazakh",
      },
    ];
    this.selectedLang = this.languages[0];
  }

  ngDoCheck(): void {
    if (
      this.currentUser === undefined ||
      this.currentUser === null ||
      (this.userRoles.length === 0 && this.currentUser.IsSystemAdmin === false)
    )
      this.getCurrentUser();
  }

  onChange(event) {
    this.translate.use(event.value["code"]);
  }

  ngOnInit(): void {
    (<any>window).initNavbarJS();
  }

  select(item: string | MenuItem) {
    this.menu.forEach((i) => delete i.selected);
    if (typeof item !== "string") {
      item.selected = true;
    }
  }

  showRateDialog() {
    this.rating.show();
  }

  getTranslations(key: string) {
    this.translate.get(key).subscribe((translatedString) => {
      if (translatedString === undefined || translatedString == "") {
        return ""; // if value is getting undefined then return default language string, en_merch is default english language file here
      } else {
        return translatedString;
      }
    });
  }

  getCurrentUser(): void {
    this.currentUser = this._authService.getCurrentUser();
    this.userRoles = this._authService.getRoles()?.map((r) => r.Role);
    this.isSysAdmin = this._authService.isSystemAdmin();
    this._cdr.detectChanges();
  }

  getFullName(): string {
    return this._authService.getFullName();
  }
}
