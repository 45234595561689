import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Feedback } from "models";
import { share } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class FeedbackService {
  constructor(private http: HttpClient) {}

  public getListByBusinessUnitName(name: string): Observable<Feedback[]> {
    return this.http
      .get<Feedback[]>(
        `${environment.apiEndpoint}/feedback/business-unit/${name}`
      )
      .pipe(share());
  }

  public create(feedback: Feedback): Observable<Feedback> {
    return this.http
      .post<Feedback>(
        `${environment.apiEndpoint}/feedback/${feedback.WorkItemType}`,
        feedback
      )
      .pipe(share());
  }

  public update(feedback: Feedback): Observable<Feedback> {
    return this.http
      .put<Feedback>(
        `${environment.apiEndpoint}/feedback/${feedback.Id}`,
        feedback
      )
      .pipe(share());
  }

  public delete(feedbackId: number) {
    return this.http
      .delete(`${environment.apiEndpoint}/feedback/${feedbackId}`)
      .pipe(share());
  }

  public postFeedback(feedback: Feedback): Observable<void> {
    return this.http.post<void>(
      `${environment.feedbackEndpoint}/feedback?appId=${environment.appCode}`,
      feedback
    );
  }
}
