import { Component, OnInit } from "@angular/core";

import { BusinessUnitsService } from "#services/api";
import { BusinessUnit } from "#models/business-unit";
import { LoadingIndicatorService } from "#services/shared/loading-indicator.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { MessageService } from "primeng/api";
import { finalize } from "rxjs";

@Component({
  selector: "app-business-units",
  templateUrl: "./business-units.component.html",
})
export class BusinessUnitsComponent implements OnInit {
  businessUnits: Array<BusinessUnit> = [];
  buForm = new UntypedFormGroup({
    Name: new UntypedFormControl("", Validators.required),
    Id: new UntypedFormControl(0),
  });

  constructor(
    private businessUnitsSvc: BusinessUnitsService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private readonly _messageService: MessageService
  ) {}

  ngOnInit() {
    this.loadBusinessUnits();
  }

  save(): void {
    this.loadingIndicatorSvc.show();
    this.businessUnitsSvc
      .create(this.buForm.value)
      .pipe(finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: BusinessUnit) => {
        this.businessUnits.push(data);
        this.buForm.reset();
      });
  }

  enable(businessUnitID: number): void {
    this.loadingIndicatorSvc.show();
    this.businessUnitsSvc
      .enableBusninessUnit(businessUnitID)
      .pipe(finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        () => {
          this._messageService.add({
            severity: "success",
            summary: "success!",
            detail: "business unit was enabled successfully",
            life: 5000,
          });
        },
        (error) => {
          this._messageService.add({
            severity: "error",
            summary: "error!",
            detail: "there was an error enabling the business unit, try again",
            life: 5000,
          });
        }
      );
  }

  disable(businessUnitID: number): void {
    this.loadingIndicatorSvc.show();
    this.businessUnitsSvc
      .deleteBusninessUnit(businessUnitID)
      .pipe(finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        () => {
          this._messageService.add({
            severity: "success",
            summary: "success!",
            detail: "business unit was disabled successfully",
            life: 5000,
          });
        },
        (error) => {
          this._messageService.add({
            severity: "error",
            summary: "error!",
            detail: "there was an error disabling the business unit, try again",
            life: 5000,
          });
        }
      );
  }

  private loadBusinessUnits(): void {
    this.loadingIndicatorSvc.show();
    this.businessUnitsSvc
      .getList()
      .pipe(finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: BusinessUnit[]) => (this.businessUnits = data));
  }
}
