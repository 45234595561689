import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { LoggedUser, User } from "models";
import { IUser } from "helpers/interfaces/user/user.interface";
import { IUserRole } from "helpers/interfaces/user/user-role.interface";
import { IDowntime } from "#models/event/downtime.model";

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  get(userID?: number): Observable<User[] | User> {
    const user = userID ? "/" + userID : "";
    return this.http.get<User[] | User>(
      `${environment.apiEndpoint}/users` + user
    );
  }

  getUsersandRoles(): Observable<User[]> {
    return this.http.get<User[]>(
      `${environment.apiEndpoint}/users/usersandroles`,
      {
        headers: {
          "Cache-Control": "no-cache",
        }
      });
  }

  put(userID: number, businessUnitID: number): Observable<User> {
    const params = { userID, businessUnitID };
    return this.http.put<User>(
      `${environment.apiEndpoint}/users`,
      {},
      { params }
    );
  }

  getByKeywordk(keyword: string): Observable<User[] | User> {
    const params = {
      ...(keyword && { Keyword: keyword }),
    };
    return this.http.get<User[] | User>(`${environment.apiEndpoint}/users`, {
      params,
    });
  }

  getRoles(userID: number): Observable<IUserRole[]> {
    return this.http.get<IUserRole[]>(
      `${environment.apiEndpoint}/users/${userID}/roles`
    );
  }

  postSiteUser(siteID: number, payload: IUser): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpoint}/sites/${siteID}/users`,
      {
        ...payload,
        UserID: payload?.ID,
      }
    );
  }

  putSiteUser(siteID: number, payload: IUser): Observable<any> {
    return this.http.put<any>(
      `${environment.apiEndpoint}/sites/${siteID}/users/${payload.ID}`,
      {
        ...payload,
        UserID: payload?.ID,
      }
    );
  }

  deleteSiteUser(siteID: number, payload: IUser): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiEndpoint}/sites/${siteID}/users/${payload.ID}`
    );
  }

  deleteUser(userID: number): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpoint}/users/${userID}/disable`,
      {}
    );
  }

  grantSysAdmin(userID: number): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpoint}/users/${userID}/grant-sysadmin-role`,
      {}
    );
  }

  revokeSysAdmin(userID: number): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpoint}/users/${userID}/revoke-sysadmin-role`,
      {}
    );
  }

  getCurrentUser(): Observable<LoggedUser> {
    return this.http.get<LoggedUser>(
      `${environment.apiEndpoint}/users/current`
    );
  }

  getUsers(query: string): Observable<User[]> {
    const params = new HttpParams().set("query", query);
    return this.http.get<User[]>(
      `${environment.apiEndpoint}/users/participants`,
      { params }
    );
  }

  getBySite(siteId: number): Observable<User[]> {
    return this.http.get<User[]>(
      `${environment.apiEndpoint}/sites/${siteId}/users`
    );
  }

  getUsersByOrg(orgId: number): Observable<User[]> {
    return this.http.get<User[]>(
      `${environment.apiEndpoint}/organizations/${orgId}/users`
    );
  }

  post(user: User): Observable<User> {
    return this.http.post<User>(`${environment.apiEndpoint}/users`, {
      ...user,
      ID: undefined,
    });
  }

  postBySite(user: User, siteId: number): Observable<User> {
    return this.http.post<User>(
      `${environment.apiEndpoint}/sites/${siteId}/users`,
      {
        ...user,
        ID: undefined,
      }
    );
  }

  putBySite(user: User, siteId: number): Observable<User> {
    return this.http.post<User>(
      `${environment.apiEndpoint}/sites/${siteId}/users`,
      {
        ...user,
      }
    );
  }

  acceptDisclaimer(userId: number): Observable<User> {
    return this.http.post<User>(
      `${environment.apiEndpoint}/users/current/accept-disclaimer`,
      null
    );
  }

  listUserGuides(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${environment.apiEndpoint}/users/guides`,
    {
      headers: {
        "Cache-Control": "max-age=3600",
      }
    });
  }

  postDowntimeUser(userId: number, downtime: IDowntime): Observable<IDowntime> {
    return this.http.post<IDowntime>(
      `${environment.apiEndpoint}/users/${userId}/downtimes`,
      { ...downtime }
    );
  }

  deleteDowntimeUser(userId: number, downtimeId: string): Observable<IDowntime> {
    return this.http.delete<IDowntime>(
      `${environment.apiEndpoint}/users/${userId}/downtimes/${downtimeId}`
    );
  }
}
