import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Feedback, BusinessUnit } from 'models';
import { FeedbackService } from '#services/api';
import { LoadingIndicatorService } from '#services/shared';
import { SelectItem } from 'primeng/api';
import { FeedbackTypeEnum, FeedbackType } from '#models/enums/feedback/feedback-type';
import { FeedbackPriority, FeedbackPriorityEnum } from '#models/enums/feedback/feedback-priority';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-feedback-create',
    templateUrl: './feedback-create.component.html',
    styleUrls: ['./feedback-create.component.scss']
})
export class FeedbackCreateComponent implements OnInit, OnDestroy {

    @Input() businessUnit: BusinessUnit;
    @Output() onCreate = new EventEmitter<Feedback>();

    visible: boolean;
    feedback: Feedback;
    types: SelectItem[];
    priorities: SelectItem[];
    subscription: Subscription;

    constructor(
        public feedbackSvc: FeedbackService,
        public loadingIndicatorSvc: LoadingIndicatorService
    ) { }

    ngOnInit() {
        this.feedback = new Feedback();
        
        this.types = Object.keys(FeedbackType)
            .filter(key => typeof FeedbackType[key] === 'number')
            .map(key => FeedbackTypeEnum.map.get(FeedbackType[key]))
            .map(desc => (<SelectItem>{ value: desc, label: desc }));

        this.priorities = Object.keys(FeedbackPriority)
            .filter(key => typeof FeedbackPriority[key] === "number")
            .map(key => FeedbackPriority[key])
            .map(item => (<SelectItem>{ value: item, label: FeedbackPriorityEnum.map.get(item) }));
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }

    save() {
        this.loadingIndicatorSvc.show();
        this.feedback.Tags = this.businessUnit.Name;

        const observable = this.feedbackSvc.create(this.feedback)
            .pipe(finalize(() => this.loadingIndicatorSvc.hide()));

        this.subscription = observable
            .subscribe(newItem => this.onCreate.emit(newItem));
    }
}
