import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Trade } from "#models/trade.model";
import { Observable } from "rxjs";

@Injectable()
export class TradesService {
  constructor(private http: HttpClient) {}

  get(tradeID?: string): Observable<Trade[] | Trade> {
    const trade = tradeID ? "/" + tradeID : "";
    let url = `${environment.apiEndpoint}/trades` + trade;
    let options = {};
    options = {
      headers: new HttpHeaders({
        "Cache-control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      }),
    };
    return this.http.get<any>(url, options);
  }

  getTradesWithSiteTrades(siteId: number): Observable<Trade[] | Trade> {
    return this.http.get<any>(
      `${environment.apiEndpoint}/trades/` + siteId + `/site-specific-trades`
    );
  }

  post(payload: any): Observable<Trade> {
    return this.http.post<Trade>(`${environment.apiEndpoint}/trades`, payload);
  }

  put(payload: any): Observable<Trade> {
    return this.http.put<Trade>(
      `${environment.apiEndpoint}/trades/${payload.ID}`,
      payload
    );
  }

  delete(payload: Trade): Observable<any> {
    return this.http.post<Trade>(
      `${environment.apiEndpoint}/trades/${payload.ID}/disable`,
      payload
    );
  }
}
