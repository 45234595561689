import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Question } from "#models/job-templates/section";
import {
  Activity,
  ActivityMetrics,
  Resource,
} from "#models/activities/activity.model";
import { WorklistItem } from "#models/event/worklist-item.model";
import { WorklistJobTemplate } from "#models/event/worklist-job-template";

import { Observable } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";

@Injectable()
export class WorlistItemService {
  form: UntypedFormGroup;
  filterForm: UntypedFormGroup;
  constructor(private http: HttpClient) {}

  get(itemId: string, appliedJobTemplateId: number) {
    return this.http.get<WorklistItem>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/${appliedJobTemplateId}?expand=true`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  getList(filter: {
    downtimeId: string;
    status?: string;
    title?: string;
    scopeId?: number;
    equipmentTypeId?: string;
    equipmentNumber?: string;
    workOrderId?: string;
    fundingType?: string;
    mocRequired?: boolean;
    primaveraSynced?: boolean;
    revSyncPrimavera?: boolean;
    detailedEstimate?: number;
    earlyEstimate?: number;
  }): Observable<Array<WorklistItem>> {
    let url = `${environment.apiEndpoint}/worklist-items?downtimeId=${filter?.downtimeId}`;

    if (filter?.status) {
      url += `&status=${filter?.status}`;
    }
    if (filter?.title) {
      url += `&title=${filter?.title}`;
    }
    if (filter?.scopeId) {
      url += `&scopeId=${filter?.scopeId}`;
    }
    if (filter?.equipmentTypeId) {
      url += `&equipmentTypeId=${filter?.equipmentTypeId}`;
    }
    if (filter?.equipmentNumber) {
      url += `&equipmentNumber=${filter?.equipmentNumber}`;
    }
    if (filter?.workOrderId) {
      url += `&workOrderId=${filter?.workOrderId}`;
    }
    if (filter?.fundingType) {
      url += `&fundingType=${filter?.fundingType}`;
    }
    if (typeof filter?.mocRequired !== "undefined") {
      url += `&isMocRequired=${filter?.mocRequired}`;
    }
    if (typeof filter?.primaveraSynced !== "undefined") {
      url += `&primaveraSynced=${filter?.primaveraSynced}`;
    }
    if (typeof filter?.revSyncPrimavera !== "undefined") {
      url += `&revSyncPrimavera=${filter?.revSyncPrimavera}`;
    }
    if (
      typeof filter?.detailedEstimate !== "undefined" &&
      filter?.detailedEstimate !== null
    ) {
      url += `&detailedEstimate=${filter?.detailedEstimate}`;
    }
    if (
      typeof filter?.earlyEstimate !== "undefined" &&
      filter?.earlyEstimate !== null
    ) {
      url += `&earlyEstimate=${filter?.earlyEstimate}`;
    }

    return this.http.get<Array<WorklistItem>>(url, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
    });
  }

  changeStatus(itemId: number, isEarlyEstimate: boolean, nextStatus: number) {
    return this.http.post(
      `${environment.apiEndpoint}/worklist-items/${itemId}/change-status`,
      {
        isEarlyEstimate,
        nextStatus,
      }
    );
  }
  getDowntimeActivitiesMetrics(downtimeID: string) {
    return this.http.get<ActivityMetrics>(
      `${environment.apiEndpoint}/worklist-items/${downtimeID}/percentage-activities`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  getWorklistItemActivitiesMetrics(
    worklistItemId: string,
    appliedJobTemplateId: string
  ) {
    return this.http.get<ActivityMetrics>(
      `${environment.apiEndpoint}/worklist-items/${worklistItemId}/${appliedJobTemplateId}/OverridenPercentageByWorklistItemLevel?PerformCalculation=true&ExcludeDurationZero=true&Page=1`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  getWLIMetricGetLatest(
    downtimeID: number,
    worklistItemId: number
    ) {
    return this.http.get<ActivityMetrics>(
      `${environment.apiEndpoint}/worklist-items-metrics/WLIMetricGetLatest/${downtimeID}/${worklistItemId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  getWLIMetricByDowntime(
    downtimeID: number,
    ) {
    return this.http.get<ActivityMetrics>(
      `${environment.apiEndpoint}/worklist-items-metrics/WLIMetricByDowntime/${downtimeID}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  changeJobTemplateStatus(
    itemId: number,
    isEarlyEstimate: boolean,
    nextStatus: number,
    appliedJobTemplateId: number
  ) {
    return this.http.post(
      `${environment.apiEndpoint}/worklist-items/${itemId}/change-job-template-status/${appliedJobTemplateId}`,
      {
        isEarlyEstimate,
        nextStatus,
      },
      {
        responseType: "text",
      }
    );
  }

  updateTemplateName(appliedJobTemplateId: number, CustomName: string, ExportName?: string) {
    return this.http.post(
      `${environment.apiEndpoint}/worklist-items/change-template-name/${appliedJobTemplateId}`,
      {
        CustomName,
        ExportName
      }
    );
  }

  exportAppliedJobTemplate(
    appliedJobTemplateId: number,
    exportStatus?: boolean,
    exportName?: string
  ) {
    return this.http.post(
      `${environment.apiEndpoint}/worklist-items/export/${appliedJobTemplateId}/${exportStatus}`,
      {
        ExportName: exportName
      }
    );
  }

  getExportedAppliedJobTemplate(
    filter: {
      equipmentType: number;
      site: number;
      createdByUser: string;
    }
  ): Observable<IPreviousEstimates[]> {

    var pageNumber = 1;
    let url = `${environment.apiEndpoint}/worklist-items/exported-estimations?PageNumber=${pageNumber}`;

    if (filter?.equipmentType) {
      url += `&equipmentType=${filter?.equipmentType}`;
    }

    if (filter?.site) {
      url += `&site=${filter?.site}`;
    }

    if (filter?.createdByUser) {
      url += `&createdByUser=${filter?.createdByUser}`;
    }
 
    return this.http.get<IPreviousEstimates[]>(url, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
    })
  }

  getFundingTypes(): Observable<Array<string>> {
    return this.http.get<Array<string>>(
      `${environment.apiEndpoint}/worklist-items/funding-types`
    );
  }

  getRevisionChanges(revisionVersion: string, worklistItemId: string) {
    return this.http.get<{ Activity: Activity; Questions: Array<Question> }>(
      `${environment.apiEndpoint}/worklist-items/revision/${revisionVersion}?itemId=${worklistItemId}`
    );
  }

  getRevisions(worklistItemId: string, appliedJobTemplateId: number) {
    return this.http.get<Array<string>>(
      `${environment.apiEndpoint}/worklist-items/revisions?itemId=${worklistItemId}&appliedJobTemplateId=${appliedJobTemplateId}`
    );
  }

  getAppliedJobTemplate(appliedJobTemplateId: number) {
    return this.http.get<Array<string>>(
      `${environment.apiEndpoint}/worklist-items/appliedJobTemplate?appliedJobTemplateId=${appliedJobTemplateId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
  }

  getQuestions(itemId: string, appliedJobTemplateId: number) {
    return this.http.get<Array<Question>>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/${appliedJobTemplateId}/questions?orderBy=SectionOrder,Order`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  saveQuestions(
    itemId: string,
    appliedJobTemplateId: number,
    payload: Array<Question>
  ) {
    return this.http.put<Array<Question>>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/${appliedJobTemplateId}/questions`,
      payload
    );
  }

  getActivities(itemId: string, appliedJobTemplateId: number) {
    return this.http.get<Array<Activity>>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/${appliedJobTemplateId}/activities`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  getManhoursByPhase(itemId: string, appliedJobTemplateId: number) {
    return this.http.get<Array<Activity>>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/${appliedJobTemplateId}/activities/phaseManhours`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  saveActivities(payload: Activity) {
    return this.http.post<Activity>(
      `${environment.apiEndpoint}/worklist-items/${payload.WorkListItemID}/activities/${payload.ID}/override`,
      payload
    );
  }

  updateActivityOrder(itemId: number, payload: Activity[]) {
    return this.http.put<Activity>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/activities/updateActivityOrder`,
      payload
    );
  }

  normalize(worklistItemId: string, ids: Array<number>, prefix: string) {
    return this.http.post<Activity>(
      `${environment.apiEndpoint}/worklist-items/${worklistItemId}/activities/normalize`,
      {
        ids,
        prefix,
      }
    );
  }

  deleteCustomActivityService(payload: Activity) {
    return this.http.post<Activity>(
      `${environment.apiEndpoint}/worklist-items/${payload.WorkListItemID}/activities/${payload.ID}/disable-custom-activity`,
      payload
    );
  }

  postCustomActivities(payload: Activity) {
    const additionalResources = payload?.WorklistActivityResources.map(
      (resource) => {
        return {
          TradeID: resource.TradeID,
          CalculatedDuration: resource.OverriddenDuration || 0,
          OverriddenDuration: resource.OverriddenDuration || 0,
          RoundedDuration: resource.OverriddenDuration || 0,
          SnapshottedDuration: resource.OverriddenDuration || 0,
          CalculatedManHours: resource.CalculatedManHours || 0,
          RoundedManHours: resource.CalculatedManHours || 0,
          SnapshottedManHours: resource.CalculatedManHours || 0,
          CrewSize: resource.OverriddenCrewSize || 0,
          OverriddenCrewSize: resource.OverriddenCrewSize || 0,
          SnapshottedCrewSize: resource.OverriddenCrewSize || 0,
          IsOverridden: true,
          Remove: resource.Remove,
          WorklistJobTemplateID: payload.WorklistJobTemplateID,
        };
      }
    );

    const customPayload = this._buildCustomPayload(
      payload,
      additionalResources
    );
    return this.http.post<Activity>(
      `${environment.apiEndpoint}/worklist-items/${payload.WorkListItemID}/activities/add-custom-activity`,
      customPayload
    );
  }

  export(itemId: string, appliedJobTemplateId: number, wbs: string) {
    const params = {
      wbs,
    };
    return this.http.get<any>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/${appliedJobTemplateId}/activities/export`,
      { params }
    );
  }

  syncToPrimavera(
    payload: {
      selectedProjectID: number;
      selectedWbsID: number;
    },
    activityPrefix: string,
    worklistItemId: number,
    appliedJobTemplateId: number,
    syncWithSmartID: boolean
  ): Observable<string[]> {
    return this.http.post<string[]>(
      `${environment.apiEndpoint}/worklist-items/${worklistItemId}/${appliedJobTemplateId}/sync-to-primavera`,
      {
        projectObjectId: payload.selectedProjectID,
        wbsObjectId: payload.selectedWbsID,
        activityPrefix: activityPrefix,
        syncWithSmartID: syncWithSmartID,
      }
    );
  }

  markAsSyncedToPrimavera(appliedJobTemplateId: number) {
    return this.http.post<any>(
      `${environment.apiEndpoint}/worklist-items/${appliedJobTemplateId}/mark-as-synced-to-primavera`,
      {}
    );
  }

  publish(itemId: string, appliedJobTemplateId: number) {
    return this.http.post<any>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/${appliedJobTemplateId}/snapshot`,
      {}
    );
  }

  put(payload: WorklistItem, itemId: string) {
    return this.http.put<any>(
      `${environment.apiEndpoint}/worklist-items/${itemId}`,
      payload
    );
  }

  postEstimateMaterialEquipment(payload: WorklistItem, itemId: string) {
    return this.http.post<any>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/equipmentMaterialCost`,
      payload
    );
  }

  putWorklistJobTemplate(payload: WorklistJobTemplate, itemId: number) {
    return this.http.put<WorklistJobTemplate>(
      `${environment.apiEndpoint}/worklist-items/appliedJobTemplate/${itemId}`,
      payload
    );
  }

  addResource(itemId: string, activityId: string, resource: Resource) {
    return this.http.post<any>(
      `${environment.apiEndpoint}/worklist-items/${itemId}/activities/${activityId}/add-resource`,
      {
        TradeID: resource.TradeID,
        OverriddenCrewSize: resource.OverriddenCrewSize,
        OverriddenDuration: resource.OverriddenDuration,
        WorklistJobTemplateID: resource.WorklistJobTemplateID,
      }
    );
  }

  getAttachments(downtimeWorklistID: string) {
    return this.http.get<Array<IAttachment>>(
      `${environment.apiEndpoint}/attachment/worklist-item`,
      {
        params: {
          downtimeWorklistID,
        },
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  postAttachment(file: File, downtimeWorklistID: string) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<Array<IAttachment>>(
      `${environment.apiEndpoint}/attachment/worklist-item`,
      formData,
      {
        params: {
          downtimeWorklistID,
        },
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  deleteAttachment(downtimeWorklistID: string, fileName: string) {
    return this.http.delete<Array<IAttachment>>(
      `${environment.apiEndpoint}/attachment/worklist-item/${downtimeWorklistID}/${fileName}`
    );
  }

  getAttachment(downtimeWorklistID: string, fileName: string) {
    return this.http.get<Blob>(
      `${environment.apiEndpoint}/attachment/worklist-item/${downtimeWorklistID}/${fileName}`
    );
  }

  getPreviousEquipmentEstimation(
    equipmentTypeID: number,
    equipmentNumber: string,
    filter: {
      equipmentType: number;
      site: number;
      createdByUser: string;
    }
  ): Observable<IPreviousEstimates[]> {
    let url = `${environment.apiEndpoint}/worklist-items/previousEstimates/${equipmentTypeID}?equipmentNumber=${equipmentNumber}`;

    if (filter?.equipmentType) {
      url += `&equipmentType=${filter?.equipmentType}`;
    }

    if (filter?.site) {
      url += `&site=${filter?.site}`;
    }

    if (filter?.createdByUser) {
      url += `&createdByUser=${filter?.createdByUser}`;
    }
 
    return this.http.get<IPreviousEstimates[]>(url, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
    })
  }

  applyPreviousEquipmentEstimation(
    appliedTemplateID: number,
    worklistItemID: number
  ): any {
    return this.http.post<IPreviousEstimates[]>(
      `${environment.apiEndpoint}/worklist-items/previousEstimates/${appliedTemplateID}/${worklistItemID}`,
      {}
    );
  }

  updateAssignedScheduler(downtimeId: number, assignedScheduler: number) {
    return this.http.get<any>(
      `${environment.apiEndpoint}/worklist-items/update-assignedscheduler/${downtimeId}/${assignedScheduler}`
    );
  }

  private _buildCustomPayload(payload: Activity, additionalResources): any {
    return {
      Code: payload?.Code,
      CalendarID: payload?.CalendarID,
      Name: payload?.Name,
      Comments: payload?.Comments,
      Notes: payload?.Notes,
      WorkListItemID: +payload?.WorkListItemID,
      WorklistJobTemplateID: payload?.WorklistJobTemplateID,
      PrimaryResource: {
        TradeID: payload?.PrimaryResource.TradeID,
        CalculatedDuration: payload?.PrimaryResource.OverriddenDuration || 0,
        OverriddenDuration: payload?.PrimaryResource.OverriddenDuration || 0,
        RoundedDuration: payload?.PrimaryResource.OverriddenDuration || 0,
        SnapshottedDuration: payload?.PrimaryResource.OverriddenDuration || 0,

        CalculatedManHours: payload?.PrimaryResource.CalculatedManHours || 0,
        RoundedManHours: payload?.PrimaryResource.CalculatedManHours || 0,
        SnapshottedManHours: payload?.PrimaryResource.CalculatedManHours || 0,

        CrewSize: payload?.PrimaryResource.OverriddenCrewSize || 0,
        OverriddenCrewSize: payload?.PrimaryResource.OverriddenCrewSize || 0,
        SnapshottedCrewSize: payload?.PrimaryResource.OverriddenCrewSize || 0,
        IsOverridden: true,
        WorklistJobTemplateID: payload?.WorklistJobTemplateID,
      },
      WorklistActivityResources: additionalResources,
      JustificationID: payload?.JustificationID,
      PhaseID: payload?.PhaseID,
      MilestoneTypeID: payload?.MilestoneTypeID,
      ExecutionActivityTypeID: payload?.ExecutionActivityTypeID,
      ProjectTypeID: payload?.ProjectTypeID,
      TurnAroundAdditionalWorkID: payload?.TurnAroundAdditionalWorkID,
      CrewSize:
        (payload?.PrimaryResource?.OverriddenCrewSize || 0) +
        this._sumProperty("OverriddenCrewSize", additionalResources),
      Duration:
        (payload?.PrimaryResource?.OverriddenDuration || 0) +
        this._sumProperty("OverriddenDuration", additionalResources),
      ManHours:
        (payload?.PrimaryResource?.CalculatedManHours || 0) +
        this._sumProperty("CalculatedManHours", additionalResources),
      RussianDescription: payload?.RussianDescription,
    };
  }

  private _sumProperty(property: string, additionalResources): number {
    try {
      return additionalResources.reduce((acc, obj) => {
        return obj.Remove !== true ? acc + obj[property] : acc;
      }, 0);
    } catch {
      return 0;
    }
  }
}

export interface IAttachment {
  CreatedDate: string;
  FileName: string;
  FilePath: string;
  FileType: string;
  Extension: string;
}

export interface IPreviousEstimates {
  CreatedBy?: string;
  CustomName: string;
  ExportName?: string;
  DetailedEstimateCost: number;
  DetailedEstimateStatus: number;
  EarlyEstimateCost: number;
  EarlyEstimateStatus: number;
  ID: number;
  JobTemplateID: string;
  PrimaveraSyncRevision: string;
  Revision: string;
  Site: string;
  SnapshottedActivities: number;
  SnapshottedCrewSize: number;
  SnapshottedDate: string;
  SnapshottedDuration: number;
  SnapshottedManHours: number;
  WorklistItemID: number;
  DowntimeName: string;
  WorklistTitle: string;
}
