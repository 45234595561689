import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";

import { ApiModule } from "#services/api/api.module";
import { LoadingIndicatorService } from "#services/shared/loading-indicator.service";
import { SharedModule } from "#services/shared/shared.module";
import { ConfirmationService } from "primeng/api";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { OrganizationComponent } from "./organization/organization.component";
import { OrganizationService } from "#services/api/organization.service";

import { AutoCompleteModule } from "primeng/autocomplete";
import { MultiSelectModule } from "primeng/multiselect";

import { UnitsComponent } from "./units/units.component";
import { ComponentsModule } from "#components/components.module";
import { TableModule } from "primeng/table";
import { BusinessUnitsComponent } from "./business-units/business-units.component";
import { AccessControlModule } from "helpers/directives/access-control/access-control.module";

import {TranslateModule,} from '@ngx-translate/core';
@NgModule({
  declarations: [BusinessUnitsComponent, OrganizationComponent, UnitsComponent],
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,

    // PrimeNG
    InputTextModule,
    ButtonModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    DialogModule,
    AutoCompleteModule,
    MultiSelectModule,
    TableModule,

    // App
    ApiModule,
    SharedModule,
    FormsModule,
    ComponentsModule,
    AccessControlModule,

    //translate
    TranslateModule,
  ],
  providers: [
    LoadingIndicatorService,
    ConfirmationService,
    OrganizationService,
  ],
})



export class AdminPagesModule {}
