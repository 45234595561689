import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Justification } from "#models/justification.model";
import { Observable } from "rxjs";

@Injectable()
export class JustificationService {
  constructor(private http: HttpClient) {}

  get(justificationID?: string): Observable<Justification[] | Justification> {
    const justification = justificationID ? "/" + justificationID : "";
    return this.http.get<any>(
      `${environment.apiEndpoint}/justifications` + justification
    );
  }

  post(payload: any): Observable<Justification> {
    return this.http.post<Justification>(
      `${environment.apiEndpoint}/justifications`,
      payload
    );
  }

  put(payload: any): Observable<Justification> {
    return this.http.put<Justification>(
      `${environment.apiEndpoint}/justifications/${payload.ID}`,
      payload
    );
  }
}
