import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { MsalService } from "@azure/msal-angular";
import { CacheLookupPolicy } from "@azure/msal-browser";
import { environment } from "#environments/environment";
import { AuthService } from "#services/shared";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly _authService: AuthService,
    private readonly _msalService: MsalService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addAuthHeader(request);
    return next.handle(request).pipe(
      catchError((error) => {
        if (error?.status === 401) {
          console.log("refreshing token");
          const account = this._msalService.instance.getActiveAccount();
          return this._msalService
            .acquireTokenSilent({
              scopes: environment.msalConfig.scopes,
              account: account,
              forceRefresh: false,
              cacheLookupPolicy: CacheLookupPolicy.Default,
            })
            .pipe(
              switchMap((tokenResponse) => {
                sessionStorage.setItem("Token", tokenResponse.accessToken);
                request = this.addAuthHeader(request);
                return next.handle(request);
              })
            );
        }
        if (error.name == "TimeoutError") {
          return next.handle(request);
        }
        return throwError(error);
      })
    );
  }

  private addAuthHeader(request: HttpRequest<any>) {
    const authHeader = this._authService.getAuthorizationHeader();
    if (authHeader) {
      let ifBlob = false;
      if (
        request.url.includes("/activities/export") ||
        request.url.includes("/attachment/worklist-item/")
      ) {
        ifBlob = true;
      }

      const headerSettings: { [name: string]: string | string[] } = {};

      for (const key of request.headers.keys()) {
        headerSettings[key] = request.headers.getAll(key);
      }
      headerSettings["Authorization"] = authHeader;
      if (sessionStorage.getItem("bu") !== null) {
        headerSettings["BU"] = sessionStorage.getItem("bu");
      }
      const newHeader = new HttpHeaders(headerSettings);

      return request.clone({
        headers: newHeader,

        ...(ifBlob ? { responseType: "blob" } : {}),
      });
    }
    return request;
  }
}
