export enum FeedbackPriority {
    Risk = 1,
    High = 2,
    Medium = 3,
    Low = 4
}

export class FeedbackPriorityEnum {
    static map = new Map<number, string> ([
        [FeedbackPriority.Risk, 'Risk'],
        [FeedbackPriority.High, 'High'],
        [FeedbackPriority.Medium, 'Medium'],
        [FeedbackPriority.Low, 'Low'],
    ]);
}