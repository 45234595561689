import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Plant } from "#models/plant.model";
import { Observable } from "rxjs";

@Injectable()
export class PlantsService {
  constructor(private http: HttpClient) {}

  getList(): Observable<Array<Plant>> {
    return this.http.get<Array<Plant>>(`${environment.apiEndpoint}/plants`);
  }

  get(): Observable<Array<Plant>> {
    return this.http.get<Array<Plant>>(`${environment.apiEndpoint}/plants`);
  }

  getById(plantId: number): Observable<Plant> {
    return this.http.get<Plant>(`${environment.apiEndpoint}/plants/${plantId}`);
  }

  post(payload: any): Observable<Plant> {
    return this.http.post<Plant>(`${environment.apiEndpoint}/plants`, payload);
  }

  put(payload: any): Observable<Plant> {
    return this.http.put<Plant>(
      `${environment.apiEndpoint}/plants/${payload.ID}`,
      payload
    );
  }
}
