import { Component, OnInit } from '@angular/core';
import { IExternalLink } from 'helpers/interfaces/quick-link-interface';


@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})

export class QuickLinksComponent implements OnInit {

  externalLinks: IExternalLink[] = [];
  
  ngOnInit(): void {
    this._getExternalLinks();
  }

  private _getExternalLinks(): void {
    const quickLinksUrl = [
      {
        "text": "components.quicklinks.serviceNow",
        "url": "https://chevron.service-now.com/itserviceportal?id=sc_cat_item&sys_id=e8244615db8a33408d52d2e3ca961945" 
      }];
      this.externalLinks = JSON.parse(JSON.stringify(quickLinksUrl));
  }
}
