export const environment = {
  production: false,
  adalConfig: {
    tenant: "chevron.onmicrosoft.com",
    clientId: "101c57e4-58bf-4bb7-971b-7e39f3923443",
    postLogoutRedirectUri: "http://localhost:44300/logout",
    endpoints: {
      "https://chevron.onmicrosoft.com/e915774d-b77c-4155-b0f3-dfd7a9413885":
        "https://chevron.onmicrosoft.com/e915774d-b77c-4155-b0f3-dfd7a9413885",
    },
  },
  msalConfig: {
    scopes: ["https://normse-test.azure.chevron.com/user_impersonation"],
    clientId: "e30163b7-047c-4751-ae78-81feb9d4c28a",
  },
  // app insights
  appInsights: {
    connectionString:
      "InstrumentationKey=485926b5-3002-4366-83f5-be79382877bb;IngestionEndpoint=https://southcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/;ApplicationId=d9477b49-d5ad-4d66-8c42-58057ae1bae7",
  },
  appCode: "webApp",
  apiEndpoint: "https://normsestimating-api-test.azure.chevron.com/api",
  feedbackEndpoint: "https://feedback-api-dev.cvx.azurewebsites.net/api",
  rateItEndpoint: "https://digitalpm-api-dev-cvx.azurewebsites.net",
  salesForceEndpoint:
    "https://chevronmfg--qa.sandbox.lightning.force.com/lightning/",
};
