import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { System } from "#models/system.model";
import { Observable } from "rxjs";

@Injectable()
export class SystemService {
  constructor(private http: HttpClient) {}

  get(): Observable<Array<System>> {
    return this.http.get<Array<System>>(`${environment.apiEndpoint}/system`);
  }

  post(payload: any): Observable<System> {
    return this.http.post<System>(`${environment.apiEndpoint}/system`, payload);
  }

  put(payload: any): Observable<System> {
    return this.http.put<System>(
      `${environment.apiEndpoint}/system/${payload.ID}`,
      payload
    );
  }
}
