import { Component } from "@angular/core";
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from "@angular/forms";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent {
  formVisible = false;
  sending = false;

  categories = ["Feedback", "Improvement", "Issue"];

  feedbackForm = new UntypedFormGroup({
    text: new UntypedFormControl("", Validators.required),
    category: new UntypedFormControl(this.categories[0], Validators.required),
  });

  showForm(): void {
    this.formVisible = true;
  }

  hideForm(): void {
    this.formVisible = false;
  }

  submitFeedback(): void {
    this.formVisible = false;
  }
}
