import { NgModule } from "@angular/core";

import { NotificationComponent } from "./notification/notification.component";
import { NotificationService } from "#services/shared";
import { CommonModule } from "@angular/common";
import { RatingComponent } from "./rating/rating.component";
import { RatingModule } from "primeng/rating";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { InputTextareaModule } from "primeng/inputtextarea";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BigTitleComponent } from "./big-title/big-title.component";
import { ApiModule } from "#services/api/api.module";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { RouterModule } from "@angular/router";
import { EquipmentClassTemplateComponent } from "./equipment-class-template/equipment-class-template.component";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { TableModule } from "primeng/table";
import { EstimateStatusComponent } from "./estimate-status/estimate-status.component";
import { OverridenActivitiesInfoComponent } from "./overriden-activities-info/overriden-activities-info.component";
import { TranslateModule } from "@ngx-translate/core";
import { CreateTagComponent } from "./create-tag/create-tag.component";
import { SidebarModule } from "primeng/sidebar";
import { UserGuideComponent } from "./user-guide/user-guide.component";
import { SkeletonModule } from "primeng/skeleton";

const components = [
  NotificationComponent,
  RatingComponent,
  BigTitleComponent,
  BreadcrumbComponent,
  EquipmentClassTemplateComponent,
  EstimateStatusComponent,
  OverridenActivitiesInfoComponent,
  CreateTagComponent,
  UserGuideComponent,
];
@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RatingModule,
    InputTextareaModule,
    ApiModule,
    RouterModule,

    // primeng
    InputTextModule,
    ButtonModule,
    MultiSelectModule,
    TableModule,
    DialogModule,
    SidebarModule,
    SkeletonModule,

    //translate
    TranslateModule,
  ],
  providers: [NotificationService],
  exports: components,
})
export class ComponentsModule {}
