import { NgModule } from "@angular/core";

import { NotificationService } from "#services/shared";
import { CommonModule } from "@angular/common";
import { RatingModule } from "primeng/rating";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { InputTextareaModule } from "primeng/inputtextarea";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { TableModule } from "primeng/table";
import { QuestionComponent } from "./question.component";
import { YesNoComponent } from "./yes-no/yes-no.component";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { NumericComponent } from "./numeric/numeric.component";
import { TextComponent } from "./text/text.component";
import { DropdownModule } from "primeng/dropdown";
import { InputNumberModule } from "primeng/inputnumber";
import { RadioButtonModule } from "primeng/radiobutton";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TranslateModule } from "@ngx-translate/core";

const components = [
  YesNoComponent,
  DropdownComponent,
  NumericComponent,
  TextComponent,
];
@NgModule({
    declarations: [...components, QuestionComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RatingModule,
        InputTextareaModule,
        RouterModule,
        // primeng
        InputTextModule,
        ButtonModule,
        MultiSelectModule,
        TableModule,
        DialogModule,
        DropdownModule,
        InputNumberModule,
        RadioButtonModule,
        InputTextModule,
        OverlayPanelModule,
        TranslateModule,
    ],
    providers: [NotificationService],
    exports: [...components, QuestionComponent]
})
export class QuestionModule {}
