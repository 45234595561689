import { UserService } from '#services/api';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { UserGuide } from '#models/utilities/userguide.model';


@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss']
})
export class UserGuideComponent implements OnInit {
  links: Array<UserGuide> = [];
  isLoading: boolean = true;
  private _destroy$ = new Subject<boolean>();
  constructor(
    private readonly _userService: UserService,
    private readonly _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.fetchUserManualList();
  }

  private fetchUserManualList() : void {
    this.isLoading = true;
    this._userService
      .listUserGuides()
      .pipe(
        takeUntil(this._destroy$),
        finalize(() => {
          this.isLoading = false;
        })
      ).subscribe((data) => {
        this.links = this.formatLinks(data)
      })
  }

  private formatLinks(links: string[]) {
    let list = [];
      links.forEach(item => {
        let title = item.split(':')[0];
        let url = item.split(':').slice(1).join(":");
        list.push({
          Title: title,
          URL: url
        });
      });
      return list;
  }
}