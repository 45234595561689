import { NgModule } from "@angular/core";
import { LoggerService, NotificationService } from "./";
import { ExcelService } from "./excel.service";

@NgModule({
  declarations: [],
  imports: [],
  providers: [LoggerService, NotificationService, ExcelService],
})
export class SharedModule {}
