import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Question } from "#models/job-templates/section";
import { UntypedFormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input()
  question: Question;
  @Input()
  control: UntypedFormControl;
  @Output()
  event = new EventEmitter();
  selectedValue:
    | { name: string; code: string }
    | Array<{ name: string; code: string }>;
  options: Array<{ name: string; code: string }> = [];
  private destroy$ = new Subject<boolean>();

  ngOnInit() {
    this.options = this.question.Options.reduce((acc, element) => {
      acc.push({ name: element.Description, code: element.Description });
      return acc;
    }, []);
    if (this.question.AnswerContainsAll) {
      this.selectedValue = this.options.filter((o) =>
        (this.question.Answer as string)?.split(",")?.includes(o.name)
      );
    } else {
      this.selectedValue = this.options.find(
        (o) => o.name === this.question.Answer
      );
    }
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value === null) {
          this.selectedValue = null;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onChange(value: { name: string; code: string }) {
    this.control.patchValue(value?.code);
  }

  onMultiSelectChange(event) {
    const value = event.value.map((element) => {
      return element?.code;
    });
    this.control.patchValue(value.join(","));
  }
}
