// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option {
  border: 1px solid #ededed;
  padding: 5px 0;
  width: 120px;
  text-align: center;
  display: inline-block;
}
.option:first-child {
  margin-right: 10px;
}
.option label {
  margin-left: 5px;
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/question/yes-no/yes-no.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,qBAAA;AACJ;AACI;EACI,kBAAA;AACR;AACI;EACI,gBAAA;EACA,gBAAA;AACR","sourcesContent":[".option {\n    border: 1px solid #ededed;\n    padding: 5px 0;\n    width: 120px;\n    text-align: center;\n    display: inline-block;\n\n    &:first-child {\n        margin-right: 10px;\n    }\n    label {\n        margin-left: 5px;\n        margin-bottom: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
