import { Component, OnInit } from "@angular/core";
import { LoadingIndicatorService } from "#services/shared/loading-indicator.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { System } from "#models/system.model";
import { SystemService } from "#services/api/system.service";
import { UnitsService } from "#services/api/units.service";
import { Units } from "#models/units.model";
import { finalize, Subject } from "rxjs";

@Component({
  selector: "app-units",
  templateUrl: "./units.component.html",
  styleUrls: ["./units.component.scss"],
})
export class UnitsComponent implements OnInit {
  units: Array<Units>;
  systems: Array<System>;
  unitForm = new UntypedFormGroup({
    Name: new UntypedFormControl("", [Validators.required]),
    Factor: new UntypedFormControl("", [Validators.required]),
    SystemID: new UntypedFormControl("", [Validators.required]),
  });
  show$ = new Subject<any>();

  constructor(
    private unitsSvc: UnitsService,
    private systemSvc: SystemService,
    public loadingIndicatorSvc: LoadingIndicatorService
  ) {}

  ngOnInit() {
    this.loadSystems();
    this.loadUnits();
  }

  save(data?: any): void {
    this.loadingIndicatorSvc.show();
    const method = data ? "put" : "post";
    const values = data ? data : this.unitForm.value;
    this.unitsSvc[method](values)
      .pipe(finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((response: Units) => {
        if (data) {
          this.show$.next("close");
          const index = this.units.findIndex((e) => e.ID === data.ID);
          this.units[index] = data;
        } else {
          this.units.push(response);
          this.unitForm.reset();
        }
      });
  }

  edit(values: any) {
    this.show$.next({
      title: "Units",
      form: this.unitForm,
      values,
      items: [
        { label: "Unit Name", control: "Name", type: "text", disabled: true },
        { label: "Factor", control: "Factor", type: "number" },
        {
          label: "System",
          control: "SystemID",
          type: "select",
          options: this.systems,
          disabled: true,
        },
      ],
    });
  }

  private loadUnits(): void {
    this.loadingIndicatorSvc.show();
    this.unitsSvc
      .get()
      .pipe(finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: Array<Units>) => (this.units = data));
  }

  private loadSystems(): void {
    this.loadingIndicatorSvc.show();
    this.systemSvc
      .get()
      .pipe(finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: Array<System>) => (this.systems = data));
  }
}
