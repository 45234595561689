// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-guide {
  padding: 20px;
  color: #0b2d71;
  font-family: "GothamBook";
  justify-content: flex-start;
}
.user-guide h4 {
  align-self: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/user-guide/user-guide.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,cAAA;EACA,yBAAA;EACA,2BAAA;AACJ;AACI;EACE,sBAAA;AACN","sourcesContent":[".user-guide{\n    padding: 20px;\n    color: #0b2d71;\n    font-family: \"GothamBook\";\n    justify-content: flex-start;\n\n    h4 {\n      align-self: flex-start;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
