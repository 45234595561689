import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { QuestionOption } from "#models/job-templates/question-option.model";
import { Observable } from "rxjs";

@Injectable()
export class QuestionOptionService {
  constructor(private http: HttpClient) {}

  get(questionId: string): Observable<Array<QuestionOption>> {
    return this.http.get<Array<QuestionOption>>(
      `${environment.apiEndpoint}/questions/${questionId}/options`
    );
  }

  post(payload: QuestionOption): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpoint}/questions/${payload.QuestionID}/options`,
      { ...payload, ID: undefined }
    );
  }

  put(payload: QuestionOption): Observable<QuestionOption> {
    return this.http.put<QuestionOption>(
      `${environment.apiEndpoint}/questions/${payload.QuestionID}/options/${payload.ID}`,
      payload
    );
  }

  delete(optionId: string, questionId: string): Observable<QuestionOption> {
    return this.http.delete<QuestionOption>(
      `${environment.apiEndpoint}/questions/${questionId}/options/${optionId}`
    );
  }
}
