import { environment } from "#environments/environment";
import { Tag } from "#models/utilities/tag.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class TagService {
    constructor(private http: HttpClient) {}

    get(): Observable<Array<Tag>> {
        return this.http.get<Array<Tag>>(`${environment.apiEndpoint}/tags`);
    }

    create(tag: Tag): Observable<Tag> {
        return this.http.post<Tag>(`${environment.apiEndpoint}/tags`, tag);
    }
}