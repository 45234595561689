import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { Observable, tap } from "rxjs";

/**
 * @description Workaround for weird angular / JSON.parse() behavior unable to automatically parse json datetime into javascript Date object
 */
@Injectable()
export class DatetimeParseInterceptor implements HttpInterceptor {
  private dateRegex =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)[zZ]?$/;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.convertDates(event.body);
        }
      })
    );
  }

  private convertDates(object: Object) {
    if (!object || !(object instanceof Object)) {
      return;
    }
    this._checkAndHandleArray(object);

    for (const key of Object.keys(object)) {
      const value = object[key];
      this._checkAndHandleArray(value);

      if (value instanceof Object) {
        this.convertDates(value);
      }

      if (typeof value === "string" && this.dateRegex.test(value)) {
        object[key] = new Date(value);
      } else if (
        value instanceof String &&
        this.dateRegex.test(value.toString())
      ) {
        object[key] = new Date(value.toString());
      }
    }
  }

  private _checkAndHandleArray(object: Object): void {
    if (object instanceof Array) {
      for (const item of object) {
        this.convertDates(item);
      }
    }
  }
}
