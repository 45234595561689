import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { Activity, ResourceTask } from "#models/activities/activity.model";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class ActivityService {
  private showRussianDesc$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  get(filter: {
    jobTemplateId?: string;
    expand?: boolean;
  }): Observable<Activity[] | Activity> {
    const params = {
      ...(filter &&
        filter.jobTemplateId && { jobTemplateId: filter.jobTemplateId }),
      ...(filter && filter.expand && { expand: filter.expand + "" }),
    };
    return this.http.get<Activity[] | Activity>(
      `${environment.apiEndpoint}/activities`,
      { params }
    );
  }

  getList(jobTemplateId?: string, expand?: boolean): Observable<Activity[]> {
    let url = `${environment.apiEndpoint}/activities?orderBy=code`;

    if (jobTemplateId) {
      url += `&jobTemplateId=${jobTemplateId}`;
    }

    if (expand) {
      url += `&expand=${expand}`;
    }

    return this.http.get<Activity[]>(url);
  }

  deleteById(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/activities/${id}`);
  }

  getById(activityId: string): Observable<Activity> {
    return this.http.get<Activity>(
      `${environment.apiEndpoint}/activities/${activityId}`
    );
  }

  post(payload: Activity): Observable<Activity> {
    const assign = { ...payload };
    if (assign.PrimaryResource) {
      assign.PrimaryResource.Tasks.forEach((rv) => {
        delete rv.ResourceID;
      });
    }
    if (assign.ActivityResources) {
      assign.ActivityResources.forEach((additionalResource) => {
        additionalResource.Tasks.forEach((rv) => {
          delete rv.ResourceID;
        });
      });
    }

    const values =
      assign.PrimaryResource.TradeID === null &&
      assign.PrimaryResource.CrewSize === null
        ? {
            ...assign,
            PrimaryResource: null,
            PrimaryResourceID: null,
            ActivityResources: null,
          }
        : { ...assign };
    return this.http.post<Activity>(
      `${environment.apiEndpoint}/activities?batch=true`,
      {
        ...values,
        ID: undefined,
        PhaseID: payload.PhaseID,
        MilestoneTypeID: payload.MilestoneTypeID,
        ExecutionActivityTypeID: payload.ExecutionActivityTypeID,
      }
    );
  }

  put(payload: Activity, reverseLogic: boolean): Observable<Activity> {
    const assign = { ...payload };
    if (assign.PrimaryResource) {
      assign.PrimaryResource.Tasks.forEach((rv) => {
        if (!rv.ResourceID) {
          delete rv.ResourceID;
        }
      });
    }

    if (assign.ActivityResources) {
      assign.ActivityResources.forEach((additionalResource) => {
        additionalResource.Tasks.forEach((rv) => {
          if (!rv.ResourceID) {
            delete rv.ResourceID;
          }
        });
      });
    }
    const values =
      assign.PrimaryResource.TradeID === null &&
      assign.PrimaryResource.CrewSize === null
        ? {
            ...assign,
            PrimaryResource: null,
            PrimaryResourceID: null,
            ActivityResources: null,
          }
        : { ...assign };
    return this.http.put<Activity>(
      `${environment.apiEndpoint}/activities?batch=true&reverseLogic=${reverseLogic}`,
      {
        ...values,
        PhaseID: payload.PhaseID,
        MilestoneTypeID: payload.MilestoneTypeID,
        ExecutionActivityTypeID: payload.ExecutionActivityTypeID,
      }
    );
  }

  copy(activityId: string): Observable<Activity> {
    return this.http.post<Activity>(
      `${environment.apiEndpoint}/activities/${activityId}/copy`,
      {}
    );
  }

  delete(payload: ResourceTask): Observable<ResourceTask> {
    return this.http.delete<ResourceTask>(
      `${environment.apiEndpoint}/activity-resource-variables/${payload.ResourceID}`
    );
  }

  deleteTask(resourceID: string, taskID: string) {
    return this.http.delete(
      `${environment.apiEndpoint}/resources/${resourceID}/tasks/${taskID}`
    );
  }

  setRussianDescriptionVisibility(show: boolean) {
    this.showRussianDesc$.next(show);
  }

  getRussianDescriptionVisibility() {
    return this.showRussianDesc$.asObservable();
  }
}
