// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .question-wrapper {
  border: 1px solid #ededee;
  background-color: #ffffff;
  min-height: 50px;
  padding: 15px 0;
  font-size: 14px;
}
:host .question-wrapper label {
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 8px;
}
:host .question-hidden {
  visibility: hidden;
  height: 0px;
  padding: 0;
  min-height: 0px;
  max-height: 0px;
  width: 0px;
  min-width: 0px;
  max-width: 0px;
  margin: 0;
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/question/question.component.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;AAAR;AAEQ;EACI,gBAAA;EACA,gBAAA;EACA,eAAA;AAAZ;AAGI;EACI,kBAAA;EACA,WAAA;EACA,UAAA;EACA,eAAA;EACA,eAAA;EACA,UAAA;EACA,cAAA;EACA,cAAA;EACA,SAAA;EACA,SAAA;AADR","sourcesContent":[":host {\n    .question-wrapper {\n        border: 1px solid #ededee;\n        background-color: #ffffff;\n        min-height: 50px;\n        padding: 15px 0;\n        font-size: 14px;\n\n        label {\n            font-weight: 400;\n            margin-bottom: 0;\n            margin-top: 8px;\n        }\n    }\n    .question-hidden {\n        visibility: hidden;\n        height: 0px;\n        padding: 0;\n        min-height: 0px;\n        max-height: 0px;\n        width: 0px;\n        min-width: 0px;\n        max-width: 0px;\n        margin: 0;\n        border: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
