export enum QuestionTypes {
    YESNO = 1, // YES-NO
    NUMERIC = 2, // NUMERIC
    DROPDOWN = 3, // DROPDOWN
    TEXT = 4, // TEXT
}

export const QuestionTypesLabels = new Map<number, string>([
    [QuestionTypes.YESNO, 'YES/NO'],
    [QuestionTypes.NUMERIC, 'NUMERIC'],
    [QuestionTypes.DROPDOWN, 'DROPDOWN'],
    [QuestionTypes.TEXT, 'TEXT'],
]);
export const QuestionTypesComponents = new Map<number, string>([
    [QuestionTypes.YESNO, 'app-yes-no'],
    [QuestionTypes.NUMERIC, 'app-numeric'],
    [QuestionTypes.DROPDOWN, 'app-dropdown'],
    [QuestionTypes.TEXT, 'app-text'],
]);
