// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .page {
  background-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/components/equipment-class-template/equipment-class-template.component.scss"],"names":[],"mappings":"AACE;EACE,wCAAA;AAAJ","sourcesContent":[":host {\n  ::ng-deep .page {\n    background-color: transparent !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
