import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { JobTemplate } from "#models/job-templates/job-template";
import { UntypedFormGroup } from "@angular/forms";
import { Section } from "#models/job-templates/section";
import { Site } from "#models/site.model";
import { Observable } from "rxjs";

@Injectable()
export class JobTemplatesService {
  jobTemplateForm: UntypedFormGroup;
  selectedSection: Section;

  constructor(private http: HttpClient) {}

  getList({ includePrivate = false, cache = true } = {}): Observable<
    Array<JobTemplate>
  > {
    let url = `${environment.apiEndpoint}/templates`;
    let options = {};

    if (includePrivate) {
      url += "?private=true";
    }

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<JobTemplate[]>(url, options);
  }

  get(jobTemplateId?: string): Observable<JobTemplate[] | JobTemplate> {
    const jobTemplate = jobTemplateId ? "/" + jobTemplateId : "";
    return this.http.get<JobTemplate[] | JobTemplate>(
      `${environment.apiEndpoint}/templates` + jobTemplate,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  copy(jobTemplateId: string): Observable<JobTemplate> {
    return this.http.post<JobTemplate>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}/copy`,
      {}
    );
  }

    copyasgeneric(jobTemplateId: string): Observable<JobTemplate> {
        return this.http.post<JobTemplate>(
            `${environment.apiEndpoint}/templates/${jobTemplateId}/copyasgeneric`,
            {}
        );
    }

    GenerateByGenericTemplate(jobTemplateId: string, genericJobTemplateId: string): Observable<string> {
        return this.http.post<string>(
            `${environment.apiEndpoint}/templates/${jobTemplateId}/GenerateByGenericTemplate/${genericJobTemplateId}`  ,
            {}
        );
    }
    
  delete(jobTemplateId: string): Observable<JobTemplate> {
    return this.http.delete<JobTemplate>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}`
    );
  }

  create(payload: JobTemplate): Observable<JobTemplate> {
    return this.http.post<JobTemplate>(`${environment.apiEndpoint}/templates`, {
      ...payload,
      ID: undefined,
    });
  }

  update(payload: JobTemplate): Observable<JobTemplate> {
    return this.http.put<JobTemplate>(
      `${environment.apiEndpoint}/templates/${payload.ID}`,
      payload
    );
  }

  getSites(jobTemplateId: string): Observable<Array<Site>> {
    return this.http.get<Array<Site>>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}/sites`
    );
  }

  resetSites(
    jobTemplateId: string,
    sites: Array<Site>
  ): Observable<Array<Site>> {
    return this.http.put<Array<Site>>(
      `${environment.apiEndpoint}/templates/${jobTemplateId}/sites`,
      sites
    );
  }
}
