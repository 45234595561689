import { LoggedUser } from "#models/logged-user";
import { AuthService } from "#services/shared";
import { Directive, Input, OnInit, ElementRef } from "@angular/core";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[accessControl]",
})
export class AccessControlDirective implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input("accessRole") accessRole: string;
  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _authSvc: AuthService
  ) {}

  ngOnInit() {
    this._elementRef.nativeElement.style.display = "none";
    this._checkAccess();
  }

  private _checkAccess() {
    const loggedUser: LoggedUser = this._authSvc.getCurrentUser();
    let allowAccess = false;
    if (loggedUser?.IsSystemAdmin) {
      allowAccess = true;
    } else if (this.accessRole) {
      if (Array.isArray(loggedUser?.Role)) {
        allowAccess = loggedUser.Role.some((ur) =>
          this.accessRole.split(",").includes(ur.Role + "")
        );
      }
    }
    this._elementRef.nativeElement.style.display = allowAccess
      ? "block"
      : "none";
  }
}
