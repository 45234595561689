import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { EquipmentType } from "#models/equipmentType.model";
import { Observable } from "rxjs";

@Injectable()
export class EquipmentTypeService {
  constructor(private http: HttpClient) {}

  get(): Observable<Array<EquipmentType>> {
    return this.http.get<Array<EquipmentType>>(
      `${environment.apiEndpoint}/equipment-types`
    );
  }

  post(payload: any): Observable<EquipmentType> {
    return this.http.post<EquipmentType>(
      `${environment.apiEndpoint}/equipment-types`,
      payload
    );
  }

  put(payload: any): Observable<EquipmentType> {
    return this.http.put<EquipmentType>(
      `${environment.apiEndpoint}/equipment-types/${payload.ID}`,
      payload
    );
  }
}
