export const equipmentClass = [
    {
        Name: 'Inlet Separator',
        EquipmentType: 'V-101 Inlet Separator 2PH 8\' x 15\' S/S, 278 psig @ 120 °F',
        Trades: 3,
        ManHours: 36,
        Duration: 2.3,
        Phase: null,
        Electrician: {
            Trade: 'Electrician',
            ManHours: 36,
            Duration: 2.3,
            Phase: null,
        },
        Operator: {
            Trade: 'Operator',
            ManHours: 36,
            Duration: 2.3,
            Phase: null,
        },
        Mechanic: {
            Trade: 'Mechanic',
            ManHours: 36,
            Duration: 2.3,
            Phase: null,
        }
    }
];
