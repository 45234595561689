import { Component, OnInit } from "@angular/core";
import { BusinessUnitsService } from "#services/api";
import { BusinessUnit } from "#models/business-unit";
import { Organization } from "#models/organization";
import { OrganizationService } from "#services/api/organization.service";
import { LoadingIndicatorService } from "#services/shared";
import { finalize } from "rxjs";

@Component({
  selector: "app-organization",
  templateUrl: "./organization.component.html",
  styleUrls: ["./organization.component.scss"],
})
export class OrganizationComponent implements OnInit {
  newOrganzationName: string = "";

  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];
  lstOrganization: Organization[] = [];

  errorMsg: string;
  successMsg: string;
  constructor(
    private businessUnitsSvc: BusinessUnitsService,
    private organizationSvc: OrganizationService,
    public loadingIndicatorSvc: LoadingIndicatorService
  ) {}

  ngOnInit() {
    this.loadBusinessUnits();
    this.loadOrganizations();
  }

  // Events

  public async createOrganization() {
    if (
      !this.newOrganzationName ||
      this.newOrganzationName.trim().length === 0
    ) {
      this.errorMsg = "Organization cannot be empty";
      setTimeout(() => {
        this.errorMsg = "";
      }, 5000);
    } else {
      this.loadingIndicatorSvc.show();
      this.organizationSvc
        .addOrganization(this.selectedBU.ID, this.newOrganzationName)
        .pipe(finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe(
          () => {
            this.successMsg = "Organization Successfully added";
            setTimeout(() => {
              this.successMsg = "";
            }, 5000);
          },
          (error) => {
            this.errorMsg = error.error.ExceptionMessage;
            setTimeout(() => {
              this.errorMsg = "";
            }, 5000);
          }
        );
    }
  }

  // Methods

  private loadBusinessUnits(): void {
    this.businessUnitsSvc
      .getBusinessUnitsAsAdmin()
      .subscribe((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        if (this.lstBusinessUnit.length > 0) {
          this.selectedBU = this.lstBusinessUnit[0];
          this.loadOrganizations();
        }
      });
  }

  loadOrganizations(): void {
    this.lstOrganization = [];
  }
}
